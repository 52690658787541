import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const DuracionDelPrograma = (props) => {
  return (
    <Container  fluid className="duracion-del-programa">
      <Row className="duracion-del-programa-row">
        <Col xs={12} sm={12} md={12} lg={10} xl={8} className="duracion-del-programa-column" >
          <h1 className="duracion-del-programa-titulo">{props.title}</h1>
          <p className="duracion-del-programa-parrafo">{props.description}</p>             
        </Col>
      </Row>
  </Container>
  )
}

export default DuracionDelPrograma
