import React from 'react'
import logoImage from "./nucleoluzcol-logo.png"

export default () => {
  return (
    <img id="main-logo"
      src={logoImage}
      height="30"
      className="d-inline-block align-top"
      alt="React Bootstrap logo"
    />
  )
}