import React from 'react'

const styles = {
  band: {
    backgroundColor: "#2e6d99",
    border: "1px solid #e3e3e3",
    borderRadius: "4px",
    height: "36px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  bandText: {
    color: "#fff",
    fontSize: "13px",
    fontWeight: "bold",
    verticalAlign: "middle",
    paddingLeft: "10px",
  },
}

const Band = ({ icon, text}) => {
  return (
    <div style={styles.band}>
      <span style={styles.bandText}>{icon} {text}</span>
    </div>
  )
}

export default Band
