import React, { useState, useEffect } from 'react'
import useRegistered from './useRegistered'
import { 
  Col,
  Container,
  Row,
  Table,
  FormControl,
  Navbar,
  NavDropdown,
  Form,
  Button,
  Nav,
} from 'react-bootstrap'

import { useUserDispatch, signOut } from 'context/user-context';
import StudentItem from './StudentItem'
import VerifyUserConfirm from './VerifyUserConfirm'
import Header from 'pages/undergraduate/components/header/Header'
import Band from 'pages/undergraduate/components/band/Band'
import specialties from '../create-inscription/components/student-form/specialties'
import residences from '../create-inscription/components/student-form/residences'

const styles = {
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    fontSize: "14px",
    backgroundColor: "#fff"
  },
  global: {
    backgroundColor: "rgb(232, 240, 245)",
  },
}

const StudentList = props => {
  const [registered, setRegistered] = useRegistered()
  const userDispatch = useUserDispatch();
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [selectedStudent, setSelectedStudent] = useState({ id: 0 })
  const [textFilter, setTextFilter] = useState("")
  const [specialtyFilter, setSpecialtyFilter] = useState("0")
  const [residenceFilter, setResidenceFilter] = useState("0")

  const selectStudent = (student) => {
    setSelectedStudent(student)
    handleShowConfirmModal()
  }

  const handleShowConfirmModal = () => setShowConfirmModal(true)
  const handleCloseConfirmModal = () => setShowConfirmModal(false)
  
  return (
    <Container fluid style={styles.global}>
      <Container fluid={true} style={styles.container} >
        <Header />
        <Band text={<div className="d-flex justify-content-between align-items-center">
          <span>Listado de estudiantes</span>
          <Button size="sm" variant="danger"
            onClick={() => signOut(userDispatch, props.history)}
          >cerrar sesión</Button>
        </div>} />
        <Navbar bg="primary" variant="dark" expand="md">
          <Navbar.Brand>Listado de estudiantes</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto"></Nav>
            <Form className="mr-md-2 mb-2 mb-md-0">
                <Form.Label className='text-white h5'>Especialidad</Form.Label>
                <Form.Control as="select" custom
                  name="specialtyFilter"
                  onChange={e => setSpecialtyFilter(e.target.value)}
                  value={specialtyFilter}
                >
                  <option value="0">Todos</option>
                  {specialties
                  .filter(s => s.value !== '').map(s => (
                    <option key={s.value} value={s.value}>{s.text}</option>
                  ))}
                </Form.Control>
            </Form>
            <Form className="mr-md-2 mb-2 mb-md-0">
                <Form.Label className='text-white h5'>Residencia</Form.Label>
                <Form.Control as="select" custom
                  name="residenceFilter"
                  onChange={e => setResidenceFilter(e.target.value)}
                  value={residenceFilter}
                >
                  <option value="0">Todos</option>
                  {residences
                  .filter(s => s.value !== '').map(s => (
                    <option key={s.value} value={s.value}>{s.text}</option>
                  ))}
                </Form.Control>
            </Form>
            <Form>
              <Form.Label className='text-white h5'>Buscador</Form.Label>
              <FormControl type="text" placeholder="Buscar" onChange={e => setTextFilter(e.target.value)} className="mr-sm-2" />
            </Form>
          </Navbar.Collapse>
        </Navbar>
        <Row className="justify-content-center mx-1">
          <Col xs={12} className="border-info rounded" style={{border: '2px solid', minHeight: "500px"}}>
            <Table striped hover responsive size="xs" className="my-3" >
              <thead>
                <tr>
                  <th className="text-center">N°</th>
                  <th className="text-center">Nombres</th>
                  <th className="text-center">Apellidos</th>
                  <th className="text-center">Especialidad</th>
                  <th className="text-center">Teléfono</th>
                  <th className="text-center">Correo</th>
                  <th className="text-center">País</th>
                  <th className="text-center">Residencia</th>
                </tr>
              </thead>
              <tbody>
                {registered
                .filter(student => (
                student.names.includes(textFilter) ||
                student.lastnames.includes(textFilter) ||
                student.specialty.name.includes(textFilter) ||
                student.phone.includes(textFilter) ||
                student.email.includes(textFilter) ||
                student.country.includes(textFilter) ||
                student.residence.name.includes(textFilter)
                ))
                .filter(student => {
                  console.log(student)
                  console.log({specialtyFilter, id: student.specialty.id})
                  return specialtyFilter === "0" ? true : Number(specialtyFilter) === Number(student.specialty.id)
                })
                .filter(student => {
                  console.log(student)
                  console.log({residenceFilter, id: student.residence.id})
                  return residenceFilter === "0" ? true : Number(residenceFilter) === Number(student.residence.id)
                })
                .map((student, index) => 
                  <StudentItem 
                    key={student.id}
                    number={index+1}
                    student={student}
                    selectStudent={selectStudent}
                  />
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        <VerifyUserConfirm
          showConfirmModal={showConfirmModal}
          handleCloseConfirmModal={handleCloseConfirmModal}
          selectedStudent={selectedStudent}
          setRegistered={setRegistered}
        />
      </Container>
    </Container>
  )
}

export default StudentList
