import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/diplimado.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const DoctoradoGerenciaEmpresarialPage = ({ registerState }) => {
  const requisitos = [
    {a:"Solicitud formal a través de una preinscripción donde se exponen los motivos de ingresar al doctorado; más la aprobación de esa solicitud por parte del Comité Académico."},
    {a:"Presentar título de maestría en el campo de las ciencias gerenciales y/o sociales."},
    {a:"Fotografía en fondo blanco o negro certificado del título académico."},
    {a:"Presentar constancia de dominio instrumental de un idioma extranjero. Presentar pruebas de experiencia en investigación durante los últimos cinco años."},
    {a:"Poseer productividad científica demostrada a través de una o más publicaciones en revistas científicas, arbitradas o indexadas. "},
    {a:"Participación activa en proyectos de investigación financiados por entes debidamente autorizados."},
    {a:"Cancelar los derechos de matrícula correspondiente, de acuerdo a las disposiciones reglamentarias."},
  ]
  const semestres = [
    {
      semestre: "I Semestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Métodos avanzados de investigación', uc:4},
        {materia: 'Epistemología de la gerencia', uc:4},
        {materia: 'Ética en las organizaciones', uc:4},
      ]
    },
    { 
      semestre : 'II Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Electiva I', uc:3},
        {materia: 'Taller de Tesis Doctoral I', uc:4},
        {materia: 'Investigación Libre I', uc:4},
        {materia: 'Actividad adicional I', uc:2},
      ]
    },
    { 
      semestre : 'III Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Electiva II', uc:3},
        {materia: 'Taller de Tesis Doctoral II ', uc:4},
        {materia: 'Investigación Libre II', uc:4},
        {materia: 'Actividad Adicional II', uc:2},
      ]
    },
    { 
      semestre : 'IV Semestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Proyecto de Tesis Doctoral', uc:8},
      ]
    },
    { 
      semestre : 'V Semestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Tesis Doctoral', uc:14},
      ]
    },
    { 
      semestre : 'Electiva I',
      uc :"U/C ",
      contenidos : [
        {materia: 'Sociedad de la información y la humanización de las organizaciones', uc:3},
        {materia: 'Prospectiva Estratégica: problemas y métodos', uc:3},
        {materia: 'La responsabilidad social. Un enfoque gerencial', uc:3},
        {materia: 'El diseño organizativo: Enfoques y tendencias contemporáneas', uc:3},
      ]
    },
    { 
      semestre : 'Electiva II',
      uc :"U/C ",
      contenidos : [
        {materia: 'La gerencia financiera en la toma de decisiones', uc:3},
        {materia: 'Tendencias modernas de diseño organizacional: redes organizacionales internacionales en zonas regionales.', uc:3},
        {materia: 'Competitividad del marketing en el marco de la globalización', uc:3},
        {materia: 'Perspectiva global del liderazgo ', uc:3},
      ]
    },
  ]
  const competencias = [
    {a:"Habilidad para el conocimiento del método científico, sus principios, etapas del proceso y tipologías des de una perspectiva transdisciplinaria."},
    {a:"Destreza para la comprensión epistemológica de la gerencia empresarial, para el dominio y aplicación de habilidades y métodos de investigación en el área del conocimiento donde se desenvuelven."},
    {a:"Disposición para la aplicación de valores éticos para integrar conocimientos científicos, analizarlos y tomar decisiones en la solución de problemas con responsabilidad social, empleando la deontología profesional."},
    {a:"Capacidad para formular, coordinar y participar en proyectos de investigación científica y tecnológica sustentados en la realidad social, cultural y productiva en el área de gerencia empresarial."},
    {a:"Habilidad para el desarrollo de investigaciones dirigidas a potenciar, en contextos académicos y profesionales, el avance científico, tecnológico, social, artístico o cultural dentro de una sociedad basada en el conocimiento."},
    {a:"Destreza para el desarrollo de líneas de investigación y proyectos en el área de la gerencia empresarial, para contribuir a la ampliación de las fronteras del conocimiento a través de la innovación permanente."},

  ]

  return (
    <>
    <CarouselComponent
      title = "Doctor (a) en Gerencia Empresarial"
      description= ""
      image={image}
      registerState={registerState}
    />
    <Container fluid >
      <Row>
        <Col>
          <hr></hr>
          <TitleDegree
            titulo="Objetivo General"
            description="Desarrollar competencias en los participantes a través de la investigación, el desarrollo de proyectos, líneas de investigación, trabajo en equipo y capacidad crítica-reflexiva, donde de manera planificada, metódica y sustentada se aborden los problemas existentes en la gerencia empresarial."
            ColorBorder="description-degree-blue"
          />    
          <hr></hr>
        </Col>
      </Row>
      <Row>
        <DuracionDelPrograma
          title ="Duración del programa"
          description = "La duración mínima es de 5 semestres (3 años) en unidades curriculares, electivas, actividades adicionales e investigaciones, incluyendo la elaboración y defensa de la Tesis Doctoral, un tiempo máximo de 5 años como estudiante regular, y un año de prórroga para la presentación y defensa de su Tesis Doctoral (justificado)."
        />
      </Row>
      <Row>
         <RequisitoDeIngreso
          title="Requisitos de Ingreso"
          description={requisitos}
         />
      </Row>
      <Row>
        <CompetenciasDelEgresado
          title="Competencias del Egresado"
          description={competencias}
        />
      </Row>
      <Row>
        <EstructuraCurricular
          semestres={semestres}
          totaluc ="Total U/C Doctorado: 60"
          color ="estructura-curricular-card-padre-blue"
        />   
      </Row>
      <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Dra. Yaneth Rincón",
                puesto:"Coordinadora del Doctorado",
                correo:""
              },
            ]}
          />
      </Row>
    </Container>
  </>
  )
}

export default DoctoradoGerenciaEmpresarialPage
