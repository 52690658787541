import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import {
  Button,
  Form,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'

import Band from '../../../../components/band'
import PersonalInformation from '../personal-information'
import AcademicData from "../academic-data"
import DocumentsToConsign from '../documents-to-consign'


const userIcon = <FontAwesomeIcon icon={faUser} />
const helpIcon = <FontAwesomeIcon icon={faQuestionCircle} />

const StudentForm = () => {
  const { submitForm, values, setFieldError, isSubmitting } = useFormikContext()
  return (
    <Form noValidate onSubmit={e => {
      e.preventDefault()
      console.log(isSubmitting)
      if(!isSubmitting) {
        if (values.baccalaureate_degree === '' &&
          values.proof_baccalaureate_degree_and_certified_note === '' &&
          values.title_registry_copy === '' &&
          values.proof_documents_in_luz_file === '') 
        {
          setFieldError("baccalaureate_degree", "Debe ingresar algún archivo de las opciones de títulos de bachiller")
        }
        else if (values.birth_certificate === '' &&
          values.certified_birth_certificate === '' &&
          values.original_filial_data === '' &&
          values.gazette === '' &&
          values.proof_of_naturalization === '') 
        {
          setFieldError("birth_certificate", "Debe ingresar algún archivo de las opciones de partida de nacimiento")
        }
        else if (values.certified_birth_certificate !== '' &&
          values.certified_birth_certificate !==  undefined &&
          (values.invoice === '' ||
          values.book === '' ||
          values.date_birth_certificate === '' ||
          values.tome === '')) 
        {
          setFieldError("certified_birth_certificate", "Al adjuntar una copia del libro o partida de nacimiento certificada debe llenar todos los campos de debajo")
        }
        else {
          submitForm()
        }
      }
    }}>
      <div className="mt-3"><Band icon={userIcon} text="Datos personales" /></div>
      <PersonalInformation />
      <div className="mt-3"><Band icon={helpIcon} text="Datos académicos" /></div>
      <AcademicData />
      <div className="mt-3"><Band icon={helpIcon} text="Documentos a consignar" /></div>
      <DocumentsToConsign />
    </Form>
  )
}

export default StudentForm
