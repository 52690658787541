const styles = {
  container: {
    backgroundColor: "#fff",
    border: "1px solid #b4cbdb",
    borderRadius: "10px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  textContent: {
    fontSize: "14px",
    color: "#2F5064"
  },
}

export default styles;