import React from 'react'
import {
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import SelectInput from 'components/forms/select-input'
import styles from './styles'
import countries from './country-list'
import states from './states-list'

const userIcon = <FontAwesomeIcon icon={faUser} />

const seniorYearsStart = 1900
const seniorYearsEnd = 2020
const seniorYearsRange = Array(seniorYearsEnd - seniorYearsStart + 1).fill().map((_, idx) => seniorYearsStart + idx)

const countryOptions = [
  { value: '', text: "Seleccione..." },
  ...countries.map(country => { return { value: country, text: country } })
]

const stateOptions = [
  { value: '', text: "Seleccione..." },
  ...states.map(state => { return { value: state, text: state } })
]

const seniorYearOptions = [
  { value: '', text: "Seleccione..." },
  ...seniorYearsRange.map(seniorYear => { return { value: seniorYear, text: seniorYear } })
]

const AcademicData = () => {
    
  return (<>
    <Container style={styles.container}>
      <Row>
        <Col xs={12} style={styles.textContent}>
          <Form.Row>
            <SelectInput fieldName="country" labelName="País donde estudió el último año de educación media diversificada" options={countryOptions} icon={userIcon} />
            <SelectInput fieldName="state" labelName="Si el país seleccionado es Venezuela, selecciona el Estado donde estudiaste" options={stateOptions} icon={userIcon} />
            <SelectInput fieldName="senior_year" labelName="Ingresa el año en el cual te graduaste de educación media diversificada" options={seniorYearOptions} icon={userIcon} />
          </Form.Row>
        </Col>
      </Row>
    </Container>
  </>)
}

export default AcademicData
