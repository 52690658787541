const styles = {
  alertText: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  container: {
    maxWidth: "940px",
    margin: "0 auto",
  },
  global: {
    backgroundColor: "rgb(232, 240, 245)",
    minHeight: "calc(100vh - 106px)",
    paddingBottom: "50px",
  },
}

export default styles