const countries = [
  "Afganistán",
  "Albania",
  "Alemania",
  "Andorra",
  "Angola",
  "Antigua",
  "Arabia",
  "Argelia",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaiyán",
  "Bahamas",
  "Bangladés",
  "Barbados",
  "Baréin",
  "Bélgica",
  "Belice",
  "Benín",
  "Bielorrusia",
  "Birmania",
  "Bolivia",
  "Bosnia",
  "Botsuana",
  "Brasil",
  "Brunéi",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Bután",
  "Cabo",
  "Camboya",
  "Camerún",
  "Canadá",
  "Catar",
  "Chad",
  "Chile",
  "China",
  "Chipre",
  "Ciudad",
  "Colombia",
  "Comoras",
  "Corea",
  "Corea",
  "Costa",
  "Costa",
  "Croacia",
  "Cuba",
  "Dinamarca",
  "Dominica",
  "Ecuador",
  "Egipto",
  "El",
  "Emiratos",
  "Eritrea",
  "Eslovaquia",
  "Eslovenia",
  "España",
  "Estados",
  "Estonia",
  "Etiopía",
  "Filipinas",
  "Finlandia",
  "Fiyi",
  "Francia",
  "Gabón",
  "Gambia",
  "Georgia",
  "Ghana",
  "Granada",
  "Grecia",
  "Guatemala",
  "Guyana",
  "Guinea",
  "Guinea",
  "Guinea",
  "Haití",
  "Honduras",
  "Hungría",
  "India",
  "Indonesia",
  "Irak",
  "Irán",
  "Irlanda",
  "Islandia",
  "Islas",
  "Islas",
  "Israel",
  "Italia",
  "Jamaica",
  "Japón",
  "Jordania",
  "Kazajistán",
  "Kenia",
  "Kirguistán",
  "Kiribati",
  "Kuwait",
  "Laos",
  "Lesoto",
  "Letonia",
  "Líbano",
  "Liberia",
  "Libia",
  "Liechtenstein",
  "Lituania",
  "Luxemburgo",
  "Macedonia",
  "Madagascar",
  "Malasia",
  "Malaui",
  "Maldivas",
  "Malí",
  "Malta",
  "Marruecos",
  "Mauricio",
  "Mauritania",
  "México",
  "Micronesia",
  "Moldavia",
  "Mónaco",
  "Mongolia",
  "Montenegro",
  "Mozambique",
  "Namibia",
  "Nauru",
  "Nepal",
  "Nicaragua",
  "Níger",
  "Nigeria",
  "Noruega",
  "Nueva",
  "Omán",
  "Países",
  "Pakistán",
  "Palaos",
  "Panamá",
  "Papúa",
  "Paraguay",
  "Perú",
  "Polonia",
  "Portugal",
  "Reino",
  "República",
  "República",
  "República",
  "República",
  "República",
  "República",
  "Ruanda",
  "Rumanía",
  "Rusia",
  "Samoa",
  "San",
  "San",
  "San",
  "Santa",
  "Santo",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra",
  "Singapur",
  "Siria",
  "Somalia",
  "Sri",
  "Suazilandia",
  "Sudán",
  "Sudán",
  "Suecia",
  "Suiza",
  "Surinam",
  "Tailandia",
  "Tanzania",
  "Tayikistán",
  "Timor",
  "Togo",
  "Tonga",
  "Trinidad",
  "Túnez",
  "Turkmenistán",
  "Turquía",
  "Tuvalu",
  "Ucrania",
  "Uganda",
  "Uruguay",
  "Uzbekistán",
  "Vanuatu",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Yibuti",
  "Zambia",
  "Zimbabue",
]

export default countries