import React from 'react'
import {
  Button,
  Col,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faGraduationCap,
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

const BoxAcademicDegree = (props) => {
  return (
    <Col xs={12} sm={12} md={6} lg={4} xl={3} className="academic-degree-column" >
      <div className={`card academic-degree-card ${props.colorClass}`}>
        <div>
          <div className="academic-degree-header">
            <FontAwesomeIcon icon={faGraduationCap} className="academic-degree-icon" />
          </div>
          <div className="academic-degree-body">
            <h1 className="academic-degree-title">{props.titulo}</h1>
            <p className="academic-degree-description">{props.descripcion}</p>
          </div>
        </div>
        <Button as={Link} to={props.enlace} className="academic-degree-button">
          Más información
        </Button>
      </div>
    </Col>
  )
}

export default BoxAcademicDegree