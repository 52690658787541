import * as Yup from 'yup'

export const schema = Yup.object({
  names: Yup.string().required("Al menos un nombre es requerido"),
  lastnames: Yup.string().required("Al menos un apellido es requerido"),
  specialty_id: Yup.number().required("La especialidad es requerida")
    .positive("El número debe ser positivo")
    .min(1,"La especialidad es requerida")
    .integer("El número debe ser un entero"),
  phone: Yup.string().required("El número de teléfono es requerido"),
  email: Yup.string().email("Debe ser un correo válido").required("El correo es requerido"),
  country: Yup.string().required("El país es requerido"),
});

export const initialValues = {
  names: '',
  lastnames: '',
  specialty_id: 0,
  phone: '',
  email: '',
  country: 'Venezuela',
}