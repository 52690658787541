import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { API_URL } from '../../../../../../constants'

const studentInitialValues = {
  cedula: '',
  firstname: '',
  secondname: '',
  lastname: '',
  secondlastname: '',
  gender_id: 0,
  birthdate: '',
  phone: '',
  email: '',
  address: '',
  foreign: false,
  disabled: false,
  country: '',
  state: '',
  senior_year: '',
  baccalaureate_degree: '',
  proof_baccalaureate_degree_and_certified_note: '',
  title_registry_copy: '',
  proof_documents_in_luz_file: '',
  birth_certificate: '',
  certified_birth_certificate: '',
  invoice: '',
  book: '',
  date_birth_certificate: '',
  tome: '',
  original_filial_data: '',
  gazette: '',
  proof_of_naturalization: '',
}

const useStudent = (payroll_number) => {
  const [student, setStudent] = useState(studentInitialValues)

  useEffect(() => {
    Axios.get(`${API_URL}/payroll/${payroll_number}`)
      .then(response => {
        console.log(response);
        setStudent(response.data)
      }).catch(error => {
        console.log(error)
        const err = Object.assign({}, error)
        console.log(err)
        setStudent(null)
      })
  },[])

  return student;
}

export default useStudent
