import React from 'react'

const UserItem = ({ number, student }) => {

  return (
      <tr>
          <td className="text-center"><b>{number}</b></td>
          <td>{student.names}</td>
          <td>{student.lastnames}</td>
          <td>{student.specialty.name}</td>
          <td>{student.phone}</td>
          <td>{student.email}</td>
          <td>{student.country}</td>
          <td>{student.residence.name}</td>
      </tr>
  )
    
}

export default UserItem