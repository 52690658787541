import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';


function TitleDegree(props) {
  return (
    <Row className="title-degree-gerenal">
        <Col xs={12} sm={12} md={6} lg={5} xl={3} className="title-degree">
          <h1>{props.titulo}</h1>
        </Col>
        <Col xs={12} sm={12} md={6} lg={5} xl={5}  className={props.ColorBorder}>
          <div className="mr-3">
            <p className="parrafo-degree">{props.description} <Link to="/postgrado/censo">Quiero censarme</Link></p>
          </div>
        </Col>
    </Row>
  );
}

export default TitleDegree; 