import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import {
  // Button,
  Navbar,
  Nav,
  NavDropdown,
  // Form,
  // FormControl,
} from 'react-bootstrap'
import MainLogo from 'components/mainlogo'
import { useUserState } from "context/user-context";

export default () => {
  var { isAuthenticated } = useUserState();
  const isAuth = isAuthenticated()
  return (
    <Navbar bg="primary" variant="dark" expand="md">
      <Navbar.Brand href="#home">
        <MainLogo />{" "}Núcleo LUZ COL
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {/* <NavLink 
            to="/usuarios" 
            className="btn btn-block"
            activeClassName="active"
            onClick={()=>this.props.changeRoute("usuarios")}
          >
              <b>{localStorage.getItem('role_id') === "2" ? "MI USUARIO" : "USUARIOS"}</b>
          </NavLink> */}
          <Nav.Link className="mr-3" as={NavLink} to="/" activeClassName="active">Inicio</Nav.Link>
          <NavDropdown title="Grados Académicos" id="basic-nav-dropdown" alignRight>
            <NavDropdown.Item as={Link} to="/doctorado-ciencias-educacion">DOCTORADO EN CIENCIAS DE LA EDUCACIÓN</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/doctorado-gerencia-empresarial">DOCTORADO EN GERENCIA EMPRESARIAL</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/maestria-gerencia-innovacion">MAESTRÍA EN GERENCIA DE LA INNOVACIÓN</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/maestria-gerencia-operaciones">MAESTRÍA EN GERENCIA DE OPERACIONES</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/especialidad-tributaciones">ESPECIALIDAD TRIBUTACIONES</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/maestria-en-gerencia-de-empresas-mencion-gerencia-financiera">MAESTRÍA EN GERENCIA DE EMPRESAS. MENCIÓN GERENCIA FINANCIERA</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/maestria-en-desarrollo-de-organizaciones-inteligentes">MAESTRÍA EN DESARROLLO DE ORGANIZACIONES INTELIGENTES (ORGINTEL)</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/especialidad-en-gerencia-municipal">ESPECIALIDAD EN GERENCIA MUNICIPAL</NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/especialidad-en-contaduria-mencion-auditoria">ESPECIALIDAD EN CONTADURÍA. MENCIÓN AUDITORÍA</NavDropdown.Item>
          </NavDropdown>
          <a className="mr-3 nav-link" href="https://nucleoluzcol.com.ve/EVA/" target="_blank" rel="noopener noreferrer">EVA</a>
          { isAuth ?
            (<Nav.Link className="mr-3" as={NavLink} to="/postgrado/censo/admin" activeClassName="active">Censados</Nav.Link>) : 
            (<Nav.Link className="mr-3" as={NavLink} to="/postgrado/censo/login" activeClassName="active">Acceder</Nav.Link>)
          }
          <Nav.Link className="mr-3 btn btn-success" as={NavLink} style={{ borderRadius: "20px", color: "white"}} to="/postgrado/censo">¡CENSO!</Nav.Link>
        </Nav>
        {/* <Form inline>
          <FormControl type="text" placeholder="Search" className="mr-sm-2" />
          <Button variant="outline-light">Buscar</Button>
        </Form> */}
      </Navbar.Collapse>
    </Navbar>
  )
}