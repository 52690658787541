import React from 'react'
import { useField } from 'formik'
import {
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap'

const TextInput = ({ fieldName, labelName, icon, sizes, ...props }) => {
  const [field, meta] = useField(fieldName)
  return (
    <Form.Group as={Col} md="4" {...sizes} controlId={`validationFormik-${fieldName}`}>
      <Form.Label>{labelName}</Form.Label>
      <InputGroup size="sm">
        { icon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1" className="bg-primary text-white">{icon}</InputGroup.Text>
        </InputGroup.Prepend>)}
        <Form.Control
          type="text"
          name={fieldName}
          value={field.value}
          onChange={field.onChange(fieldName)}
          onBlur={field.onBlur(fieldName)}
          isInvalid={!!meta.error && meta.touched}
          {...props}
        />
        { meta.touched && (<Form.Control.Feedback type="invalid">
          {meta.error}
        </Form.Control.Feedback>)}
      </InputGroup>
    </Form.Group>
  )
}

export default TextInput
