import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/doctorado_gerencia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const EspecialidadEnContaduriaMenciónAuditoriaPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fondo negro o blanco del título académico certificado por la universidad de la cual egresó."},
    {a:"Notas certificadas de pregrado certificado por la universidad de la cual egresó."},
    {a:"Currículo vitae simple."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carnet."},
    {a:"Fotocopia de la inscripción militar."},
  ]
  const semestres = [
    {
      semestre: "Curso de Nivelación",
      uc :"U/C ",
      contenidos : [
        {materia: 'Contexto económico, político y social venezolano', uc:1},
        {materia: 'Modelos gerenciales', uc:2},
        {materia: 'Estadística aplicada', uc:2},
        {materia: 'Seminario: Metodología de la investigación aplicada a la contaduría', uc:1},
      ]
    },
    { 
      semestre : 'Ciclo Profesional',
      uc :"U/C ",
      contenidos : [
        {materia: 'Principios y normas de auditoría', uc:3},
        {materia: 'Auditoría financiera', uc:3},
        {materia: 'Auditoría tributaria', uc:3},
        {materia: 'Auditoría de sistemas de información', uc:3},
        {materia: 'Control y evaluación de gestión', uc:3},
        {materia: 'Auditoría de gestión', uc:3},
        {materia: 'Seminario: Taller Formación de Líneas de Trabajo', uc:2},
      ]
    },
    { 
      semestre : 'Electivas (Se cursa 1)',
      uc :"U/C ",
      contenidos : [
        {materia: 'Auditoría operacional', uc:2},
        {materia: 'Auditoría de sistema de calidad', uc:2},
        {materia: 'Auditoría de personal', uc:2},
        {materia: 'TRABAJO ESPECIAL DE GRADO', uc:6},
      ]
    },
  ]
  const competencias = [
    {a:"Aplicar herramientas para las soluciones a problemas específicos en el campo de la auditoría."},
    {a:"Desarrollar habilidades de observación, descripción y análisis de los procesos que se presentan en las organizaciones públicas y privadas de modo que se conviertan en agentes de cambio en la solución de los problemas del entorno empresarial."},
    {a:"Comprender, analizar y evaluar los problemas de auditoría que presentan las organizaciones."},
    {a:"Desarrollar herramientas gerenciales tendentes a dar solución a problemas concretos en el área de auditoría."},
    {a:"Liderar equipos de trabajos altamente competitivos en el área gerencial."}
  ]

  return (
    <>
      <CarouselComponent
        title = "Especialista en Contaduría Mención: Auditoría"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Contribuir a la formación teórica y técnica de especialistas en el campo de la auditoría."
              ColorBorder="description-degree-green"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa de la Especialidad en Contaduría, mención Auditoría, tiene una duración aproximada de dos años académicos, incluyendo la presentación del Trabajo Especial de Grado. El tiempo máximo, según el Reglamento de Estudios para graduados vigente, para culminar el programa, es de cuatro años."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Msc. Norma Cherema",
                puesto:"Coordinadora de la Maestría",
                correo:""
              },
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default EspecialidadEnContaduriaMenciónAuditoriaPage
