import React from 'react'
import {
  Col,
  Container,
  Row,
} from 'react-bootstrap'

import BoxAcademicDegree from './BoxAcademicDegree'

const doctorates = [
  {
    id: 1,
    titulo: "Doctor (a) en Ciencias de la Educación",
    descripcion: "La duración mínima es de 5 semestres (3 años) en Unidades Curriculares, electivas, actividades adicionales e investigaciones, incluyendo la elaboración y defensa de la Tesis Doctoral, un tiempo máximo de 5 años como estudiante regular, y un año de prórroga para la presentación y defensa de su Tesis Doctoral (justificado).",
    colorClass: "academic-degree-card-color-blue",
    enlace :"/doctorado-ciencias-educacion"
  },
  {
    id: 2,
    titulo: "Doctor (a) en Gerencia Empresarial",
    descripcion: "La duración mínima es de 5 semestres (3 años) en unidades curriculares, electivas, actividades adicionales e investigaciones, incluyendo la elaboración y defensa de la Tesis Doctoral, un tiempo máximo de 5 años como estudiante regular, y un año de prórroga para la presentación y defensa de su Tesis Doctoral (justificado).",
    colorClass: "academic-degree-card-color-blue",
    enlace :"/doctorado-gerencia-empresarial"
  },
]

const masters = [
  {
    id: 1,
    titulo: "Magíster Scientiarum en Gerencia de la Innovación",
    descripcion: "La Maestría en Gerencia de la Innovación comprende cuatro (4) semestres de escolaridad (02 años) y un año (1) para la elaboración del Trabajo de Grado.",
    colorClass: "academic-degree-card-color-green",
    enlace :"/maestria-gerencia-innovacion"
  },
  {
    id: 2,
    titulo: "Magíster Scientiarum en Gerencia de Operaciones",
    descripcion: "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno.",
    colorClass: "academic-degree-card-color-green",
    enlace :"/maestria-gerencia-operaciones"
  },
  {
    id: 3,
    titulo: "Magíster en Gerencia de Empresas Mención Gerencia Financiera",
    descripcion: "El programa Maestría en Gerencia de Empresas mención Gerencia Financiera tiene una duración de dos años académicos, incluyendo la presentación del Trabajo de Grado. El tiempo máximo para culminar el trabajo, según establece el Reglamento de Estudios para Graduados vigente, es de cuatro años a partir de la fecha de ingreso al programa.",
    colorClass: "academic-degree-card-color-green",
    enlace :"/maestria-en-gerencia-de-empresas-mencion-gerencia-financiera"
  },
  {
    id: 4,
    titulo: "Magíster en Desarrollo de Organizaciones Inteligentes",
    descripcion: "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno.",
    colorClass: "academic-degree-card-color-green",
    enlace :"/maestria-en-desarrollo-de-organizaciones-inteligentes"
  },
]

const specialties = [
  {
    id: 1,
    titulo: "Especialista en Tributación",
    descripcion: "El programa Tributación tiene una duración aproximada de 24 meses  académicos, estipulados de la siguiente forma: 18 meses de escolaridad y los 6 restantes para la elaboración y presentación del Trabajo Especial de Grado.",
    colorClass: "academic-degree-card-color-purple",    
    enlace :"/especialidad-tributaciones"
  },
  {
    id: 2,
    titulo: "Especialista en Gerencia Municipal",
    descripcion: "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno.",
    colorClass: "academic-degree-card-color-purple",    
    enlace :"/especialidad-en-gerencia-municipal"
  },
  {
    id: 3,
    titulo: "Especialista en Contaduría Mención Auditoría",
    descripcion: "El programa de la Especialidad en Contaduría, mención Auditoría, tiene una duración aproximada de dos años académicos, incluyendo la presentación del Trabajo Especial de Grado. El tiempo máximo, según el Reglamento de Estudios para graduados vigente, para culminar el programa, es de cuatro años.",
    colorClass: "academic-degree-card-color-purple",    
    enlace :"/especialidad-en-contaduria-mencion-auditoria"
  },
]

const BoxAcademicDegreeContaner = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={12}>
          <div className="academic-degrees-boxes">
            <Row className="academic-degree-container">
              {doctorates.map(degree => (
                <BoxAcademicDegree
                  key={degree.id}
                  titulo={degree.titulo}
                  descripcion={degree.descripcion}
                  colorClass={degree.colorClass}
                  enlace = {degree.enlace}
                />
              ))}
            </Row>
            <Row className="academic-degree-container">
              {masters.map(degree => (
                <BoxAcademicDegree
                  key={degree.id}
                  titulo={degree.titulo}
                  descripcion={degree.descripcion}
                  colorClass={degree.colorClass}
                  enlace = {degree.enlace}
                />
              ))}
            </Row>
            <Row className="academic-degree-container">
              {specialties.map(degree => (
                <BoxAcademicDegree
                  key={degree.id}
                  titulo={degree.titulo}
                  descripcion={degree.descripcion}
                  colorClass={degree.colorClass}
                  enlace = {degree.enlace}
                />
              ))}
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default BoxAcademicDegreeContaner
