import React from 'react'
import { 
  NavLink,
  Redirect,
  withRouter,
} from 'react-router-dom';
import {
  Alert,
  Button,
  Container,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'

import Band from '../../components/band'
import Header from '../../components/header'
import Payroll from './components/payroll'
import useStudent from './components/use-student'

import { useUserState } from "context/user-context";

const userIcon = <FontAwesomeIcon icon={faUser} />
const helpIcon = <FontAwesomeIcon icon={faQuestionCircle} />

const styles = {
  alertText: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  container: {
    maxWidth: "940px",
    margin: "0 auto",
  },
  global: {
    backgroundColor: "rgb(232, 240, 245)",
  },
}

const ShowInscriptionPayroll = props => {
  const student = useStudent(props.match.params.payroll_number)
  var { isAuthenticated } = useUserState();
  const isAuth = isAuthenticated()
  
  if (student) {
    return (<>
      <Container fluid style={styles.global}>
        <Container style={styles.container}>
          <Container>
            <Header />
            { isAuth && (<Button as={NavLink} to="/inscripcion/admin">Volver a la lista</Button>)}
            <div className="mt-3"><Band icon={userIcon} text="Planilla" /></div>
            <div className="mt-2"><Payroll student={student} /></div>
            <div className="mt-2">
              <Alert variant="primary">No debe impimir la planilla, solo debe levar su cédula laminada y sus documentos el día de la consignación de los mismos</Alert>
            </div>
          </Container>
        </Container>
      </Container>
    </>)
  } else {
    return <Redirect to="/inscripcion" />
  }
}

export default withRouter(ShowInscriptionPayroll);