import React from 'react'
import {
  Badge as BadgeBootstrap,
  Col,
  Container,
  Row,
  Button,
} from 'react-bootstrap'

const styles = {
  container: {
    backgroundColor: "#fff",
    border: "1px solid #b4cbdb",
    borderRadius: "10px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  badgeNumber: {
    color: "white", borderRadius: "15px", paddingLeft: "0.4rem", paddingRight: "0.4rem"
  },
  list: {
    listStyleType: "lower-alpha",
  },
  listElement: {
    paddingBottom: "10px"
  },
  textContent: {
    fontSize: "14px",
    color: "#2F5064"
  },
}

const Badge = (props) => {
  return <><BadgeBootstrap variant={props.variant} style={{fontSize: "13px", fontWeight: "700", ...props.style}}>{props.children}</BadgeBootstrap>{" "}</>
}

const Instructions = () => {
  return (
      <Container style={styles.container}>
        <Row>
          <Col xs={12} style={styles.textContent}>
            <p className="text-justify">
              <b>Proceso de consignación de documentos </b>
              Proceso dirigido por la Dirección Docente de LUZ que consiste en la recepción de los documentos exigidos a los estudiantes asignados a la Institución.
            </p>
            <p className="text-justify">
              <b>Planilla de consignación de documentos </b>
              Documento que genera el sistema de admisión de la Universidad del Zulia, donde debes registrar tus datos personales, académicos y los documentos que consignarás ante la institución.
            </p>
            <p className="text-justify px-2">
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Consulta tu asignación:</Badge>
              Ingresa el número de cédula de identidad sin puntos ejemplo: <b>27456654</b> y presiona el botón <b>Consultar</b>. Si fuiste asignado, el sistema te permitirá generar tu planilla de consignación de documentos.
            </p>
            <p className="text-justify">
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Pasos para completar el formulario de la planilla de consignación.</Badge>
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>1</Badge>
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Introduce tus datos personales:</Badge>
            </p>
            <ol style={styles.list}>
              <li style={styles.listElement}>Primer nombre.</li>
              <li style={styles.listElement}>Segundo nombre (si lo tienes).</li>
              <li style={styles.listElement}>Primer apellido.</li>
              <li style={styles.listElement}>Segundo apellido (si lo tienes).</li>
              <li style={styles.listElement}>Sexo.</li>
              <li style={styles.listElement}>Fecha de nacimiento con el siguiente formato <b>día/mes/año</b> ejemplo: 25/02/1996.</li>
              <li style={styles.listElement}>Teléfono fijo y/o teléfono móvil con el formato <b>0000-0000000</b> ejemplo 0426-5555555.</li>
              <li style={styles.listElement}>Correo electrónico.</li>
              <li style={styles.listElement}>Dirección de habitación, donde vivirás durante tu permanencia en la Universidad.</li>
              <li style={styles.listElement}>Foráneo: Responde <b>Sí</b>, si provienes de otro estado y vivirás temporalmente en la ciudad de Maracaibo para cursar tus estudios.</li>
              <li style={styles.listElement}>Discapacidad: Responde <b>Sí</b>, si posees algún tipo de discapacidad y está certificada por alguno de los entes competentes tales como Coripdis y/o Conapdis.</li>
            </ol>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>2</Badge>
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Introduce tus datos académicos:</Badge>
            </p>
            <ol style={styles.list}>
              <li style={styles.listElement}>País: selecciona el país donde estudiaste el último año de educación media diversificada.</li>
              <li style={styles.listElement}>Estado: si el país seleccionado es Venezuela, selecciona el Estado donde estudiaste.</li>
              <li style={styles.listElement}>Año de egreso: Ingresa el año en el cual te graduaste de educación media diversificada.</li>
            </ol>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>3</Badge>
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Selecciona y adjunta los documentos a consignar:</Badge>
              <br/>
              <BadgeBootstrap variant="danger" className="mt-2">NOTA:</BadgeBootstrap> Los documentos deben ser <b>adjuntados en formato PDF</b> y no pesar más de 20 Megabytes por archivo.
            </p>
            <ol style={styles.list}>
              <li style={styles.listElement}><b>Datos de pago:</b> El pago para generar la planilla ha sido eliminado ahora ingresar a la Universidad del Zulia no tiene costo.</li>
              <li style={styles.listElement}><b>Título de bachiller u otros documentos:</b> Selecciona la opción <b>Título Original</b> con su respectivo timbre fiscal de 0,1 UT, si tu título fue obtenido en otro país éste debe haber sido convalidado ante la Zona Educativa. Si <b>NO posees</b> tu título original lee cuidadosamente las opciones y selecciona los documentos que consignarás:</li>
              <div>
                <ul>
                  <li style={styles.listElement}><b>Constancia de tramitación del título de bachiller + notas certificadas originales:</b> Documento con vigencia no mayor a 3 meses que certifica que tu título de bachiller está en proceso de emisión (lo emite la Zona Educativa y la institución de donde egresó). Notas certificadas originales de 1ro a 5to año, con sello y firma del plantel. Ambos documentos, incluso cada planilla de notas, debe llevar su respectivo timbre fiscal por un monto de (0,3 UT).</li>
                  <li style={styles.listElement}><b>Copia Certificada del Registro del Título:</b> Documento emitido por la Zona Educativa de la ciudad donde cursaste estudios o el plantel donde egresaste como bachiller y se solicita por extravío o deterioro del título. Tiene la misma validez legal que el título de bachiller original.</li>
                  <li style={styles.listElement}><b>Constancia de documentos en el archivo de LUZ:</b> Constancia emitida por la Dirección Docente de LUZ parar personas que en algún momento ingresaron a LUZ y no cursaron estudios, este certifica que tus documentos se encuentran en la Unidad de Archivo, para solicitar dicho documento debes dirigirte a la sección de trámites multiples de la Dirección Docente (en la antigua sede rectoral).</li>
                </ul>
              </div>
              <li style={styles.listElement}><b>Partida de nacimiento u otros documentos:</b> selecciona la opción Partida de nacimiento original (en buen estado) si la posees. Si eres extranjero tu partida de nacimiento debe estar legalizada o apostillada por el consulado venezolano en el país de nacimiento. Si No tienes partida de nacimiento lee cuidadosamente las opciones disponibles que la sustituirán:</li>
              <div>
                <ul>
                  <li style={styles.listElement}><b>Copia del libro o partida de nacimiento certificada:</b> Emitida por la prefectura que indica folio, libro, fecha y tomo; certificada por un notario público.</li>
                  <li style={styles.listElement}><b>Datos filiatorios en original:</b> si eres venezolano(a) cedulado(a) y no posees partida de nacimiento por deterioro, extravío o problemas en la prefectura y el registro, puedes consignar una certificación de datos filiatorios emitida por el Servicio Administrativo de Identificación, Migración y Extranjería (SAIME).</li>
                  <li style={styles.listElement}><b>Gaceta:</b> De ser extranjero nacionalizado, puedes presentar la Gaceta Oficial que acredite la nacionalidad venezolana.</li>
                  <li style={styles.listElement}><b>Constancia de naturalización:</b> Documento que certifica ante las autoridades competentes el hecho que un(a) ciudadano(a)extranjero(a) ha adquirido la nacionalidad venezolana, a través de la naturalización.</li>
                </ul>
              </div>
            </ol>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>4</Badge>
              Una vez que cumplas con todos los pasos anteriores presiona el botón <b>Crear Planilla</b>
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>5</Badge>
              Cuando completes el formulario para crear la planilla, te será mostrado en pantalla un mensaje de confirmación y tu <b>número de planilla</b>. La opción <b>Imprimir</b> estará disponible.
              <br/>
              <BadgeBootstrap variant="danger">NOTA:</BadgeBootstrap> Recuerda guardar tu <b>número de planilla</b>, será necesario para una futura modificación o reimpresión de la planilla de consignación.
            </p>
            <p className="text-justify">
              <Badge  style={{whiteSpace: "normal"}} variant="primary">Pasos para modificar (Datos Personales) o reimprimir la planilla de consignación de documentos.</Badge>
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>1</Badge>
              Para poder modificar tu planilla de consignación de documentos es indispensable que no hayas consignado tus documentos en la Universidad del Zulia.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>2</Badge>
              Ingresa tu número de cédula sin puntos <b>ejemplo: 27456654</b> y presiona el botón <b>Consultar</b>, si generaste tu planilla, el sistema te mostrará la opción de ingresar código de planilla y los botones de <b>Modificar</b> e <b>Imprimir</b> estarán disponibles.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>3</Badge>
              Puedes realizar modificaciones si así lo deseas , luego ingresa el número de planilla y presiona el botón <b>Modificar</b>, para que los nuevos cambios se guarden en el sistema.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>4</Badge>
              Si modificas o no, para reimprimir tu planilla presiona el botón <b>Imprimir</b>.
              <br/>
              <BadgeBootstrap variant="danger">NOTA:</BadgeBootstrap> Recuerda guardar tu <b>número de planilla</b>, será necesario para una futura modificación o reimpresión de la planilla de consignación.
            </p>
            <p className="text-justify">
              <Badge  style={{whiteSpace: "normal"}} variant="primary">¿Cómo organizo los documentos para ser entregados el día de la consignación?</Badge>
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>1</Badge>
              En un sobre manila tipo carta, con marcador escribe en grande y legible tu número de cédula.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>2</Badge>
              Coloca en el sobre los documentos en el siguiente orden con un clip (no engrape):
            </p>
            <div>
              <ul>
                <li style={styles.listElement}>Copia de la cédula de identidad ampliada.</li>
                <li style={styles.listElement}>Partida de nacimiento original o documentos que la sustituyan.</li>
                <li style={styles.listElement}>Titulo original de bachiller o documentos que lo reemplacen.</li>
              </ul>
            </div>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>3</Badge>
              2 copias impresas de la planilla de consignación de documentos debidamente firmadas con bolígrafo. No van incluidas dentro del sobre manila.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>4</Badge>
              Es importante mantener dentro del sobre sólo los documentos solicitados en el orden que se te indican, de lo contrario puedes perder tu turno para ser atendido.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>5</Badge>
              Debes verificar que esté escrito de manera correcta tu número de <b>cédula</b> y tu <b>nombre completo</b> en la planilla de consignación de documentos, si estos datos están errados podría ser necesario que modifiques tu planilla.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>6</Badge>
              En la planilla de consignación de documentos verifica el día, el lugar y la hora que debes consignar.
            </p>
            <p className="text-justify">
              <Badge variant="info" style={styles.badgeNumber}>7</Badge>
              Una vez que generes la planilla verfica el día, hora y lugar de consignación.
            </p>
            <p className="text-justify">
              <Badge  style={{whiteSpace: "normal"}} variant="primary">¿Cómo organizo los documentos para ser entregados el día de la consignación?</Badge>
            </p>
            <p className="text-justify">
            {/* <BadgeBootstrap variant="danger">NOTA:</BadgeBootstrap> Si alguien usurpó tu identidad y se registró con tu número de cédula, con tu cédula laminada, debes dirigirte a la Unidad de Apoyo Técnico de la Dirección Docente ubicado en la sede nueva del Rectorado de LUZ en el segundo piso. */}
              <BadgeBootstrap variant="danger">NOTA:</BadgeBootstrap> Si alguien usurpó tu identidad y se registró con tu número de cédula, debes crear un <Button size="sm" variant="link">ticket de reclamo</Button> con tu cédula laminada escaneada, la Unidad de Apoyo Técnico de la Dirección Docente te estará enviando un correo con las instrucciones a realizar.
            </p>
          </Col>
        </Row>
      </Container>
  )
}

export default Instructions