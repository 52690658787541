export default [
  {
    value: '',
    text: "Seleccione..."
  },
  { "value": "1", "text": "Doctor (a) en Ciencias de la Educación" },
  { "value": "2", "text": "Doctor (a) en Gerencia Empresarial" },
  { "value": "3", "text": "Magíster Scientiarum en Gerencia de la Innovación" },
  { "value": "4", "text": "Magíster Scientiarum en Gerencia de Operaciones" },
  { "value": "5", "text": "Especialista en Tributación" },
  { "value": "6", "text": "Magíster en Gerencia de Empresas Mención Gerencia Financiera" },
  { "value": "7", "text": "Magíster en Desarrollo de Organizaciones Inteligentes" },
  { "value": "8", "text": "Especialista en Gerencia Municipal" },
  { "value": "9", "text": "Especialista en Contaduría Mención Auditoría" },
];