import React from 'react'
import {
  Col,
  Form,
  InputGroup,
  Button,
} from 'react-bootstrap'
import { API_URL } from '../../constants'

const ShowFile = ({ name, value, icon, sizes, ...props }) => {
  return (
    <Form.Group as={Col} md="4" {...sizes} controlId={`validationFormik-${name}`}>
      <Form.Label>{name}</Form.Label>
      <InputGroup size="sm">
        <Button href={`${API_URL}/${value}`} target="_blank" size="sm">
          Descargar
        </Button>
      </InputGroup>
    </Form.Group>
  )
}

export default ShowFile
