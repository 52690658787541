export default [
  {
    value: '',
    text: "Seleccione..."
  },
  {
    "value": "Afganistán",
    "text": "Afganistán"
  },
  {
    "value": "Albania",
    "text": "Albania"
  },
  {
    "value": "Argelia",
    "text": "Argelia"
  },
  {
    "value": "Samoa Americana",
    "text": "Samoa Americana"
  },
  {
    "value": "Andorra",
    "text": "Andorra"
  },
  {
    "value": "Angola",
    "text": "Angola"
  },
  {
    "value": "Anguilla",
    "text": "Anguilla"
  },
  {
    "value": "Antártida",
    "text": "Antártida"
  },
  {
    "value": "Antigua y Barbuda",
    "text": "Antigua y Barbuda"
  },
  {
    "value": "Argentina",
    "text": "Argentina"
  },
  {
    "value": "Armenia",
    "text": "Armenia"
  },
  {
    "value": "Aruba",
    "text": "Aruba"
  },
  {
    "value": "Australia",
    "text": "Australia"
  },
  {
    "value": "Austria",
    "text": "Austria"
  },
  {
    "value": "Azerbaiyán",
    "text": "Azerbaiyán"
  },
  {
    "value": "Bahamas",
    "text": "Bahamas"
  },
  {
    "value": "Baréin",
    "text": "Baréin"
  },
  {
    "value": "Banglades",
    "text": "Banglades"
  },
  {
    "value": "Barbados",
    "text": "Barbados"
  },
  {
    "value": "Bielorrusia",
    "text": "Bielorrusia"
  },
  {
    "value": "Bélgica",
    "text": "Bélgica"
  },
  {
    "value": "Belice",
    "text": "Belice"
  },
  {
    "value": "Benin",
    "text": "Benin"
  },
  {
    "value": "Bermudas",
    "text": "Bermudas"
  },
  {
    "value": "Butan",
    "text": "Butan"
  },
  {
    "value": "Bolivia",
    "text": "Bolivia"
  },
  {
    "value": "Bosnia-Herzegovina",
    "text": "Bosnia-Herzegovina"
  },
  {
    "value": "Botsuana",
    "text": "Botsuana"
  },
  {
    "value": "Brasil",
    "text": "Brasil"
  },
  {
    "value": "Territorio Británico del Océano Índico",
    "text": "Territorio Británico del Océano Índico"
  },
  {
    "value": "Brunei",
    "text": "Brunei"
  },
  {
    "value": "Bulgaria",
    "text": "Bulgaria"
  },
  {
    "value": "Burkina Faso",
    "text": "Burkina Faso"
  },
  {
    "value": "Burundi",
    "text": "Burundi"
  },
  {
    "value": "Camboya",
    "text": "Camboya"
  },
  {
    "value": "Camerún",
    "text": "Camerún"
  },
  {
    "value": "Canadá",
    "text": "Canadá"
  },
  {
    "value": "Cabo Verde",
    "text": "Cabo Verde"
  },
  {
    "value": "Islas Caimán",
    "text": "Islas Caimán"
  },
  {
    "value": "República Centroafricana",
    "text": "República Centroafricana"
  },
  {
    "value": "Chad",
    "text": "Chad"
  },
  {
    "value": "Chile",
    "text": "Chile"
  },
  {
    "value": "China",
    "text": "China"
  },
  {
    "value": "Isla de Navidad",
    "text": "Isla de Navidad"
  },
  {
    "value": "Islas Cocos",
    "text": "Islas Cocos"
  },
  {
    "value": "Colombia",
    "text": "Colombia"
  },
  {
    "value": "Comoras",
    "text": "Comoras"
  },
  {
    "value": "Congo",
    "text": "Congo"
  },
  {
    "value": "República Democrática del Congo",
    "text": "República Democrática del Congo"
  },
  {
    "value": "Islas Cook",
    "text": "Islas Cook"
  },
  {
    "value": "Costa Rica",
    "text": "Costa Rica"
  },
  {
    "value": "Costa de Marfil",
    "text": "Costa de Marfil"
  },
  {
    "value": "Croacia",
    "text": "Croacia"
  },
  {
    "value": "Cuba",
    "text": "Cuba"
  },
  {
    "value": "Chipre",
    "text": "Chipre"
  },
  {
    "value": "Chequia",
    "text": "Chequia"
  },
  {
    "value": "Dinamarca",
    "text": "Dinamarca"
  },
  {
    "value": "Yibuti",
    "text": "Yibuti"
  },
  {
    "value": "Dominica",
    "text": "Dominica"
  },
  {
    "value": "República Dominicana",
    "text": "República Dominicana"
  },
  {
    "value": "Ecuador",
    "text": "Ecuador"
  },
  {
    "value": "Egipto",
    "text": "Egipto"
  },
  {
    "value": "El Salvador",
    "text": "El Salvador"
  },
  {
    "value": "Guinea Ecuatorial",
    "text": "Guinea Ecuatorial"
  },
  {
    "value": "Eritrea",
    "text": "Eritrea"
  },
  {
    "value": "Estonia",
    "text": "Estonia"
  },
  {
    "value": "Etiopía",
    "text": "Etiopía"
  },
  {
    "value": "Islas Malvinas",
    "text": "Islas Malvinas"
  },
  {
    "value": "Islas Feroe",
    "text": "Islas Feroe"
  },
  {
    "value": "Fiyi",
    "text": "Fiyi"
  },
  {
    "value": "Finlandia",
    "text": "Finlandia"
  },
  {
    "value": "Francia",
    "text": "Francia"
  },
  {
    "value": "Guayana Francesa",
    "text": "Guayana Francesa"
  },
  {
    "value": "Polinesia Francesa",
    "text": "Polinesia Francesa"
  },
  {
    "value": "Gabón",
    "text": "Gabón"
  },
  {
    "value": "Gambia",
    "text": "Gambia"
  },
  {
    "value": "Georgia",
    "text": "Georgia"
  },
  {
    "value": "Alemania",
    "text": "Alemania"
  },
  {
    "value": "Ghana",
    "text": "Ghana"
  },
  {
    "value": "Gibraltar",
    "text": "Gibraltar"
  },
  {
    "value": "Grecia",
    "text": "Grecia"
  },
  {
    "value": "Groenlandia",
    "text": "Groenlandia"
  },
  {
    "value": "Granada",
    "text": "Granada"
  },
  {
    "value": "Guadalupe",
    "text": "Guadalupe"
  },
  {
    "value": "Guam",
    "text": "Guam"
  },
  {
    "value": "Guatemala",
    "text": "Guatemala"
  },
  {
    "value": "Guernsey",
    "text": "Guernsey"
  },
  {
    "value": "Guinea",
    "text": "Guinea"
  },
  {
    "value": "Guinea-Bisau",
    "text": "Guinea-Bisau"
  },
  {
    "value": "Guyana",
    "text": "Guyana"
  },
  {
    "value": "Haití",
    "text": "Haití"
  },
  {
    "value": "Ciudad del Vaticano",
    "text": "Ciudad del Vaticano"
  },
  {
    "value": "Honduras",
    "text": "Honduras"
  },
  {
    "value": "Hong Kong",
    "text": "Hong Kong"
  },
  {
    "value": "Hungría",
    "text": "Hungría"
  },
  {
    "value": "Islandia",
    "text": "Islandia"
  },
  {
    "value": "India",
    "text": "India"
  },
  {
    "value": "Indonesia",
    "text": "Indonesia"
  },
  {
    "value": "Irán",
    "text": "Irán"
  },
  {
    "value": "Iraq",
    "text": "Iraq"
  },
  {
    "value": "Irlanda",
    "text": "Irlanda"
  },
  {
    "value": "Isla de Man",
    "text": "Isla de Man"
  },
  {
    "value": "Israel",
    "text": "Israel"
  },
  {
    "value": "Italia",
    "text": "Italia"
  },
  {
    "value": "Jamaica",
    "text": "Jamaica"
  },
  {
    "value": "Japón",
    "text": "Japón"
  },
  {
    "value": "Jersey",
    "text": "Jersey"
  },
  {
    "value": "Jordania",
    "text": "Jordania"
  },
  {
    "value": "Kazajistán",
    "text": "Kazajistán"
  },
  {
    "value": "Kenia",
    "text": "Kenia"
  },
  {
    "value": "Kiribati",
    "text": "Kiribati"
  },
  {
    "value": "Corea del Norte",
    "text": "Corea del Norte"
  },
  {
    "value": "Corea del Sur",
    "text": "Corea del Sur"
  },
  {
    "value": "Kosovo",
    "text": "Kosovo"
  },
  {
    "value": "Kuwait",
    "text": "Kuwait"
  },
  {
    "value": "Kirguistán",
    "text": "Kirguistán"
  },
  {
    "value": "Laos",
    "text": "Laos"
  },
  {
    "value": "Letonia",
    "text": "Letonia"
  },
  {
    "value": "Líbano",
    "text": "Líbano"
  },
  {
    "value": "Lesoto",
    "text": "Lesoto"
  },
  {
    "value": "Liberia",
    "text": "Liberia"
  },
  {
    "value": "Libia",
    "text": "Libia"
  },
  {
    "value": "Liechtenstein",
    "text": "Liechtenstein"
  },
  {
    "value": "Lituania",
    "text": "Lituania"
  },
  {
    "value": "Luxemburgo",
    "text": "Luxemburgo"
  },
  {
    "value": "Macao",
    "text": "Macao"
  },
  {
    "value": "República de Macedonia",
    "text": "República de Macedonia"
  },
  {
    "value": "Madagascar",
    "text": "Madagascar"
  },
  {
    "value": "Malaui",
    "text": "Malaui"
  },
  {
    "value": "Malasia",
    "text": "Malasia"
  },
  {
    "value": "Maldivas",
    "text": "Maldivas"
  },
  {
    "value": "Malí",
    "text": "Malí"
  },
  {
    "value": "Malta",
    "text": "Malta"
  },
  {
    "value": "Islas Marshall",
    "text": "Islas Marshall"
  },
  {
    "value": "Martinica",
    "text": "Martinica"
  },
  {
    "value": "Mauritania",
    "text": "Mauritania"
  },
  {
    "value": "Mauricio",
    "text": "Mauricio"
  },
  {
    "value": "Mayotte",
    "text": "Mayotte"
  },
  {
    "value": "México",
    "text": "México"
  },
  {
    "value": "Estados Federados de Micronesia",
    "text": "Estados Federados de Micronesia"
  },
  {
    "value": "Moldavia",
    "text": "Moldavia"
  },
  {
    "value": "Monaco",
    "text": "Monaco"
  },
  {
    "value": "Mongolia",
    "text": "Mongolia"
  },
  {
    "value": "Montenegro",
    "text": "Montenegro"
  },
  {
    "value": "Montserrat",
    "text": "Montserrat"
  },
  {
    "value": "Marruecos",
    "text": "Marruecos"
  },
  {
    "value": "Mozambique",
    "text": "Mozambique"
  },
  {
    "value": "Birmania",
    "text": "Birmania"
  },
  {
    "value": "Namibia",
    "text": "Namibia"
  },
  {
    "value": "Nauru",
    "text": "Nauru"
  },
  {
    "value": "Nepal",
    "text": "Nepal"
  },
  {
    "value": "Holanda",
    "text": "Holanda"
  },
  {
    "value": "Antillas Holandesas",
    "text": "Antillas Holandesas"
  },
  {
    "value": "Nueva Caledonia",
    "text": "Nueva Caledonia"
  },
  {
    "value": "Nueva Zelanda",
    "text": "Nueva Zelanda"
  },
  {
    "value": "Nicaragua",
    "text": "Nicaragua"
  },
  {
    "value": "Niger",
    "text": "Niger"
  },
  {
    "value": "Nigeria",
    "text": "Nigeria"
  },
  {
    "value": "Niue",
    "text": "Niue"
  },
  {
    "value": "IslaNorfolk",
    "text": "IslaNorfolk"
  },
  {
    "value": "IslasMarianasdelNorte",
    "text": "IslasMarianasdelNorte"
  },
  {
    "value": "Noruega",
    "text": "Noruega"
  },
  {
    "value": "Omán",
    "text": "Omán"
  },
  {
    "value": "Pakistán",
    "text": "Pakistán"
  },
  {
    "value": "Palaos",
    "text": "Palaos"
  },
  {
    "value": "Panamá",
    "text": "Panamá"
  },
  {
    "value": "Papúa Nueva Guinea",
    "text": "Papúa Nueva Guinea"
  },
  {
    "value": "Paraguay",
    "text": "Paraguay"
  },
  {
    "value": "Perú",
    "text": "Perú"
  },
  {
    "value": "Filipinas",
    "text": "Filipinas"
  },
  {
    "value": "Islas Pitcairn",
    "text": "Islas Pitcairn"
  },
  {
    "value": "Polonia",
    "text": "Polonia"
  },
  {
    "value": "Portugal",
    "text": "Portugal"
  },
  {
    "value": "Puerto Rico",
    "text": "Puerto Rico"
  },
  {
    "value": "Qatar",
    "text": "Qatar"
  },
  {
    "value": "Rumania",
    "text": "Rumania"
  },
  {
    "value": "Rusia",
    "text": "Rusia"
  },
  {
    "value": "Ruanda",
    "text": "Ruanda"
  },
  {
    "value": "Reunion",
    "text": "Reunion"
  },
  {
    "value": "San Bartolome",
    "text": "San Bartolome"
  },
  {
    "value": "Santa Elena, Ascensión y Tristán de Acuña",
    "text": "Santa Elena, Ascensión y Tristán de Acuña"
  },
  {
    "value": "San Cristóbal y Nieves",
    "text": "San Cristóbal y Nieves"
  },
  {
    "value": "Santa Lucía",
    "text": "Santa Lucía"
  },
  {
    "value": "Isla de San Martín",
    "text": "Isla de San Martín"
  },
  {
    "value": "San Pedro y Miquelon",
    "text": "San Pedro y Miquelon"
  },
  {
    "value": "San Vicente y las Granadinas",
    "text": "San Vicente y las Granadinas"
  },
  {
    "value": "Samoa",
    "text": "Samoa"
  },
  {
    "value": "San Marino",
    "text": "San Marino"
  },
  {
    "value": " Santo Tomé y Príncipe",
    "text": " Santo Tomé y Príncipe"
  },
  {
    "value": "Arabia Saudita",
    "text": "Arabia Saudita"
  },
  {
    "value": "Senegal",
    "text": "Senegal"
  },
  {
    "value": "Serbia",
    "text": "Serbia"
  },
  {
    "value": "Seychelles",
    "text": "Seychelles"
  },
  {
    "value": "Sierra Leona",
    "text": "Sierra Leona"
  },
  {
    "value": "Singapur",
    "text": "Singapur"
  },
  {
    "value": "Eslovaquia",
    "text": "Eslovaquia"
  },
  {
    "value": "Eslovenia",
    "text": "Eslovenia"
  },
  {
    "value": "Islas Salomón",
    "text": "Islas Salomón"
  },
  {
    "value": "Somalia",
    "text": "Somalia"
  },
  {
    "value": "Sudáfrica",
    "text": "Sudáfrica"
  },
  {
    "value": "Sudán del Sur",
    "text": "Sudán del Sur"
  },
  {
    "value": "España",
    "text": "España"
  },
  {
    "value": "Sri Lanka",
    "text": "Sri Lanka"
  },
  {
    "value": "Estado de Palestina",
    "text": "Estado de Palestina"
  },
  {
    "value": "Sudán",
    "text": "Sudán"
  },
  {
    "value": "Surinam",
    "text": "Surinam"
  },
  {
    "value": "Svalbard y Jan Mayen",
    "text": "Svalbard y Jan Mayen"
  },
  {
    "value": "Suazilandia",
    "text": "Suazilandia"
  },
  {
    "value": "Suecia",
    "text": "Suecia"
  },
  {
    "value": "Suiza",
    "text": "Suiza"
  },
  {
    "value": "Siria",
    "text": "Siria"
  },
  {
    "value": "Taiwán",
    "text": "Taiwán"
  },
  {
    "value": "Tayikistán",
    "text": "Tayikistán"
  },
  {
    "value": "Tanzania",
    "text": "Tanzania"
  },
  {
    "value": "Tailandia",
    "text": "Tailandia"
  },
  {
    "value": "Timor Oriental",
    "text": "Timor Oriental"
  },
  {
    "value": "Togo",
    "text": "Togo"
  },
  {
    "value": "Tokelau",
    "text": "Tokelau"
  },
  {
    "value": "Tonga",
    "text": "Tonga"
  },
  {
    "value": "Trinidad y Tobago",
    "text": "Trinidad y Tobago"
  },
  {
    "value": "Túnez",
    "text": "Túnez"
  },
  {
    "value": "Turquía",
    "text": "Turquía"
  },
  {
    "value": "Turkmenistán",
    "text": "Turkmenistán"
  },
  {
    "value": "Islas Turcas y Caicos",
    "text": "Islas Turcas y Caicos"
  },
  {
    "value": "Tuvalu",
    "text": "Tuvalu"
  },
  {
    "value": "Uganda",
    "text": "Uganda"
  },
  {
    "value": "Ucrania",
    "text": "Ucrania"
  },
  {
    "value": "Emiratos Árabes Unidos",
    "text": "Emiratos Árabes Unidos"
  },
  {
    "value": "Reino Unido",
    "text": "Reino Unido"
  },
  {
    "value": "Estados Unidos",
    "text": "Estados Unidos"
  },
  {
    "value": "Uruguay",
    "text": "Uruguay"
  },
  {
    "value": "Uzbekistán",
    "text": "Uzbekistán"
  },
  {
    "value": "Vanuatu",
    "text": "Vanuatu"
  },
  {
    "value": "Venezuela",
    "text": "Venezuela"
  },
  {
    "value": "Vietnam",
    "text": "Vietnam"
  },
  {
    "value": "Islas Vírgenes Británicas",
    "text": "Islas Vírgenes Británicas"
  },
  {
    "value": "Islas Vírgenes de los Estados Unidos",
    "text": "Islas Vírgenes de los Estados Unidos"
  },
  {
    "value": "Wallis y Futuna",
    "text": "Wallis y Futuna"
  },
  {
    "value": "Yemen",
    "text": "Yemen"
  },
  {
    "value": "Zambia",
    "text": "Zambia"
  },
  {
    "value": "Zimbabue",
    "text": "Zimbabue"
  },
  {
    "value": "Åland",
    "text": "Åland"
  }
]