import React from 'react'
import {
  Button,
  // Button,
  Carousel,
} from 'react-bootstrap'
import { Redirect } from 'react-router'
import Image1 from "./image1.jpg"
import { useHistory } from 'react-router-dom';
import { NavLink } from 'react-router-dom'


const CarouselComponent = ({ registerState }) => {
  const history = useHistory();
  console.log({ registerState });
  
  const contents = registerState === "standard" ? [
      { 
        img: Image1,
        title: "¡Transformando el presente y futuro!",
        description: <span></span>
      },
    ] :  registerState === "censo" ? [
      { 
        img: Image1,
        title: <>
                <div>¡Censos de especialidades, maestrias y doctorados abiertos!</div>
                <div><Button onClick={() => history.push("/postgrado/censo") }>Quiero censarme</Button></div>
              </>, 
        // description:  <span>
        //                 Si quieres censarte en una maestría o doctorado, entra{''}
        //                 <Button as={NavLink} to="/inscripcion" variant="success" style={{ borderRadius: "20px" }}>AQUÍ</Button>
        //                 {' '} para censarte
        //               </span>
      },
    ] :  registerState === "inscripcion" ? [
      { img: Image1, title: "¡Inscripiciones de postgrado abiertas!", 
        description: <span>Si eres nuevo ingreso para estudios de pregrado, entra <Button as={NavLink}
        to="/inscripcion" variant="success" style={{ borderRadius: "20px" }}>AQUÍ</Button> para inscribirte</span>
      },
    ] : [];
  
  return (
    <Carousel className="carousel-programa">
      {contents.map((content, index) => (
        <Carousel.Item key={index}>
          <div className="d-block w-100" style={{backgroundColor: "#00000088", height: "100%", position: "absolute"}}>
          </div>
          <img
            className="d-block w-100"
            src={content.img}
            alt="First slide"
          />
          <Carousel.Caption>
            <div>
              <h1>{content.title}</h1>
              {content.description ? (<h5>{content.description}</h5>) : '' }
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      ))}
    </Carousel>
  )
}

export default CarouselComponent
