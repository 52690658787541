import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
// import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/doctorado_gerencia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const MaestriaEnGerenciaDeEmpresasMencionGerenciaFinancieraPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fondo negro o blanco del título académico certificado por la universidad de la cual egresó."},
    {a:"Notas certificadas de pregrado certificado por la universidad de la cual egresó."},
    {a:"Currículo vitae simple."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carnet."},
    {a:"Fotocopia de la inscripción militar."},
  ]
  const semestres = [
    {
      semestre: "Área Básica",
      uc :"U/C ",
      contenidos : [
        {materia: 'Liderazgo y Organización', uc: 3},
        {materia: 'Análisis de la Economía Venezolana', uc:3},
        {materia: 'Diagnóstico Financiero', uc: 3},
        {materia: 'Fundamentos de Mercadeo', uc: 3},
        {materia: 'Gerencia de Operaciones', uc: 3},
        {materia: 'Estrategias y Políticas Gerenciales', uc:3},
        {materia: 'Seminario: Introducción a la Investigación', uc: 3},
      ]
    },
    { 
      semestre : 'Área Profesional',
      uc :"U/C ",
      contenidos : [
        {materia: 'Gerencia Financiera e Inflación', uc: 4},
        {materia: 'Estrategias Financieras Empresariales', uc: 4},
        {materia: 'Finanzas Internacionales', uc: 3},
        {materia: 'Mercado de Capitales', uc: 3},
        {materia: 'Seminario: Bases Metodológicas para la Presentación de Trabajos de Grado', uc: 3},
      ]
    },
    { 
      semestre : 'Electivas',
      uc :"U/C ",
      contenidos : [
        {materia: 'Planificación y Control Presupuestario', uc: 3},
        {materia: 'Instituciones Financieras', uc: 3},
        {materia: 'Legislación y Negocios', uc: 3},
      ]
    },
    { 
      semestre : '',
      uc :"U/C ",
      contenidos : [
        {materia: 'TRABAJO DE GRADO', uc: 6},
      ]
    },
  ]
  // const competencias = [
  //   {a:"Aplicar herramientas para las soluciones a problemas específicos en el campo de la auditoría."},
  //   {a:"Desarrollar habilidades de observación, descripción y análisis de los procesos que se presentan en las organizaciones públicas y privadas de modo que se conviertan en agentes de cambio en la solución de los problemas del entorno empresarial."},
  //   {a:"Comprender, analizar y evaluar los problemas de auditoría que presentan las organizaciones."},
  //   {a:"Desarrollar herramientas gerenciales tendentes a dar solución a problemas concretos en el área de auditoría. "},
  //   {a:"Liderar equipos de trabajos altamente competitivos en el área gerencial."}
  // ]

  return (
    <>
      <CarouselComponent
        title = "Magister Scientiarum en Gerencia de Empresas mención Gerencia Financiera"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="El programa Maestría en Gerencia de Empresas tiene la finalidad de suministrar la mejor y más avanzada educación posible en el campo de la gerencia para preparar al profesional en la gestión de proyectos y empresas."
              ColorBorder="description-degree-green"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa Maestría en Gerencia de Empresas mención Gerencia Financiera tiene una duración de dos años académicos, incluyendo la presentación del Trabajo de Grado. El tiempo máximo para culminar el trabajo, según establece el Reglamento de Estudios para Graduados vigente, es de cuatro años a partir de la fecha de ingreso al programa."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        {/* <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row> */}
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default MaestriaEnGerenciaDeEmpresasMencionGerenciaFinancieraPage
