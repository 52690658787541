import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/doctorado_gerencia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const MaestriaGerenciaOperacionesPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fondo negro o blanco del título académico certificado por la universidad de la cual egresó."},
    {a:"Notas certificadas de pregrado certificado por la universidad de la cual egresó."},
    {a:"Currículo vitae simple."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carnet."},
    {a:"Fotocopia de la inscripción militar."},
  ]
  const semestres = [
    {
      semestre: "I Cuatrimestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Desarrollo de Habilidades Gerenciales', uc:2},
        {materia: 'Análisis Financiero Empresarial', uc:2},
        {materia: 'Dirección de Marketing', uc:2},
      ]
    },
    { 
      semestre : 'II Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Fundamentos de Administración de Operaciones', uc:3},
        {materia: 'Planeación Estratégica', uc:2},
        {materia: 'Estadística para Investigadores', uc:2},
      ]
    },
    { 
      semestre : 'III Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Gestión de Operaciones', uc:3},
        {materia: 'Electiva I', uc:3},
        {materia: 'Seminario de Investigación I: Epistemología de las Operaciones', uc:3},
      ]
    },
    { 
      semestre : 'IV Cuatrimestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Gestión de la Logística', uc:3},
       {materia: 'Electiva II', uc:3},
       {materia: 'Seminario de Investigación II: Bases para la presentación del Trabajo de grado', uc:3},
      ]
    },
    { 
      semestre : 'V Cuatrimestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Trabajo de Grado', uc:""},
      ]
    },
    { 
      semestre : 'Electivas',
      uc :"U/C ",
      contenidos : [
       {materia: 'Gestión de Proyectos.', uc:3},
       {materia: 'Gestión de Costos y Presupuestos.', uc:3},
       {materia: 'Gestión de la Tecnología e Innovación.', uc:3},
       {materia: 'Ética y Responsabilidad Social.', uc:3},
       {materia: 'Gestión de Operaciones en Empresas de Servicio.', uc:3},
       {materia: 'Gestión de Transporte.', uc:3},
       {materia: 'Gestión de Mantenimiento.', uc:3},
       {materia: 'Gestión de Inventarios.', uc:3},
       {materia: 'Políticas y Estrategias de Operación para la Industria', uc:3},
       {materia: 'Logística de Mantenimiento.', uc:3},
       {materia: 'Gestión de la Calidad y la Productividad.', uc:3},
       {materia: 'Gestión de Seguridad, Salud y Medio Ambiente en Operaciones.', uc:3},
       {materia: 'Gestión del Conocimiento.', uc:3},
      ]
    },
  ]
  const competencias = [
    {a:"Realizar diagnósticos, proponer alternativas de solución para los problemas en los sistemas de operaciones y participar en equipos de mejoramiento continuo."},
    {a:"Reconocer la importancia de operar de manera adecuada los sistemas de producción mediante la utilización de criterios de efectividad y productividad."},
    {a:"Impulsar el desarrollo de las organizaciones a través del diseño, mejora continua y optimización de productos y procesos con un enfoque sistémico."},
    {a:"Formular e implementar estrategias operacionales basadas en la planificación estratégica que considere las variables del entorno que afectan la competitividad de la organización."},
    {a:"Analizar y determinar los aspectos científicos y tecnológicos que puedan generar valor al producto y proceso. "},
    {a:"Gestionar el conocimiento de la organización y asesorar a la misma."},
    {a:"Tomar decisiones ágiles y precisas considerando los conocimientos científicos, tecnológicos y de gestión operacional que apliquen al caso"}
  ]

  return (
    <>
      <CarouselComponent
        title = "Magíster Scientiarum en Gerencia de Operaciones"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Desarrollar competencias en los maestrantes basadas en conocimientos, habilidades y destrezas para desempeñarse en el campo gerencial de las operaciones, del desarrollo científico y tecnológico, mediante el dominio de herramientas teóricas metodológicas."
              ColorBorder="description-degree-green"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Msc. José Perozo",
                puesto:"Coordinador de la Maestría",
                correo:""
              },
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default MaestriaGerenciaOperacionesPage
