import React from 'react'
import { useField, useFormikContext } from 'formik'
import {
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap'

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

const DateInput = ({ fieldName, labelName, icon, sizes, ...props }) => {
  const [field, meta] = useField(fieldName)
  const { setFieldValue } = useFormikContext()
  const FORMAT = 'dd/MM/yyyy';
  return (
    <Form.Group as={Col} md="4" {...sizes} controlId={`validationFormik-${fieldName}`}>
      <Form.Label>{labelName}</Form.Label>
      <InputGroup size="sm">
        { icon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1" className="bg-primary text-white">{icon}</InputGroup.Text>
        </InputGroup.Prepend>)}
        <DayPickerInput
          inputProps={{ 
            className: `form-control form-control-sm ${meta.touched && meta.error && ("is-invalid")}`,
            name: fieldName,
            id: `validationFormik-${fieldName}`,
            onChange: field.onChange(fieldName),
            onBlur: field.onBlur(fieldName),
            ...props
          }}
          formatDate={formatDate}
          format={FORMAT}
          parseDate={parseDate}
          placeholder="DD/MM/AAAA"
          onDayChange={day => {
            setFieldValue(fieldName, day)
          }}
          value={field.value}
          onBlur={field.onBlur(fieldName)}
        />
        { meta.touched && meta.error && (<div className="invalid-feedback d-block">
          {meta.error}
        </div>)}
      </InputGroup>
    </Form.Group>
  )
}

export default DateInput
