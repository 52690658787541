import React from 'react'
import logoLuz from 'components/footer/img/escudo_de_luz.png'
import logo from 'components/footer/img/nucleoluzcol-logo-square-small.png'
const Footer = (Props) => {
  return (
    <>
    <div className="d-none d-md-block">
      <footer className="footer-contenedor ">
        <img src={logoLuz} alt="" className="footer-logo-luz"/>
        <h5 className="footer-title">{Props.title}</h5>
        <img src={logo} alt="" className="footer-logo"/>
      </footer>
    </div>
   

    <div className="d-block d-md-none">
      <footer className="footer-contenedor ">
        <h5 className="footer-title">{Props.title}</h5>
        <div className="footer-contenedor-img">
          <img src={logoLuz} alt="" className="footer-logo-luz"/>
          <img src={logo} alt="" className="footer-logo"/>
        </div>
      </footer>
    </div>

    </>
  )
}

export default Footer
