import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/cursos-a-distancia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'


const DoctoradoCienciasEducacionPage = ({ registerState }) => {
  const requisitos = [
    {a:"Solicitud formal a través de una preinscripción en la que exponga los motivos de ingresar al doctorado; más la aprobación de esa solicitud por parte del Comité Académico."},
    {a:"Presentar título de maestría en el área de educación."},
    {a:"Fotografía en fondo blanco certificado del título académico."},
    {a:"Constancia de apoyo institucional de inserción de su proyecto de investigación."},
    {a:"Presentar, defender y aprobar ante el Comité Académico del Programa un protocolo de investigación en el área de conocimiento."},
    {a:"Presentar constancia de dominio instrumental de un idioma extranjero."},
    {a:"Presentar pruebas de experiencia en investigación durante los últimos cinco años."},
    {a:"Poseer productividad científica demostrada a través de una o más publicaciones en revistas científicas, arbitradas o indexadas. "},
    {a:"Participación activa en proyectos de investigación financiados por entes debidamente autorizados."},
    {a:"Cancelar los derechos de matrícula correspondiente, de acuerdo a las disposiciones reglamentarias."},
  ]
  const semestres = [
    {
      semestre: "I Semestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Métodos avanzados de investigación', uc:4},
        {materia: 'Epistemología de la gerencia', uc:4},
        {materia: 'Ética en las organizaciones', uc:4},
      ]
    },
    { 
      semestre : 'II Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Electiva I', uc:3},
        {materia: 'Taller de Tesis Doctoral I', uc:4},
        {materia: 'Investigación Libre I', uc:4},
        {materia: 'Actividad adicional I', uc:2},
      ]
    },
    { 
      semestre : 'III Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Electiva II', uc:3},
        {materia: 'Taller de Tesis Doctoral II ', uc:4},
        {materia: 'Investigación Libre II', uc:4},
        {materia: 'Actividad Adicional II', uc:2},
      ]
    },
    { 
      semestre : 'IV Semestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Proyecto de Tesis Doctoral', uc:8},
      ]
    },
    { 
      semestre : 'V Semestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Tesis Doctoral', uc:14},
      ]
    },
    { 
      semestre : 'Electiva I',
      uc :"U/C ",
      contenidos : [
        {materia: 'Estudios sobre conocimiento y educación superior. Perspectivas teóricas e investigaciones históricas', uc:3},
        {materia: 'Metodología comparativa de investigación en ciencias de la educación', uc:3},
        {materia: 'Herramientas tecnológicas aplicadas a la educación superior', uc:3},
        {materia: 'Meta-teoría y educación', uc:3},
      ]
    },
    { 
      semestre : 'Electiva II',
      uc :"U/C ",
      contenidos : [
        {materia: 'La comunicación como campo y objeto de estudio', uc:3},
        {materia: 'Interdisciplinariedad: desafío para la educación superior y la investigación', uc:3},
        {materia: 'Teoría, metodología y usos del análisis del discurso en las ciencias', uc:3},
        {materia: 'Gestión del conocimiento', uc:3},
      ]
    },
  ]
  const competencias = [
    {a:"Habilidad para el conocimiento del método científico, sus principios, etapas del proceso y tipologías desde una perspectiva transdisciplinaria"},
    {a:"Destreza para la comprensión epistemológica de las ciencias de la educación, para el dominio y aplicación de habilidades y métodos de investigación en el área del conocimiento donde se desenvuelven."},
    {a:"Disposición para la aplicación de valores éticos para integrar conocimientos científicos, analizarlos y tomar decisiones en la solución de problemas con responsabilidad social, empleando la deontología profesional."},
    {a:"Capacidad para formular, coordinar y participar en proyectos de investigación científica y tecnológica sustentados en la realidad social, cultural y productiva en el área de las ciencias de la educación"},
  ]

  return (
    <>
      <CarouselComponent
        title = "Doctor (a) en Ciencias de la Educación"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Desarrollar competencias en los doctorandos para la investigación y trabajo en equipo, a través de una formación organizada en dos ámbitos: uno general (técnicas, métodos de investigación, herramientas y recursos) y otro de especialización propios de las diferentes líneas de investigación consolidadas en el programa."
              ColorBorder="description-degree-blue"
            />
          <hr></hr>     
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "La duración mínima es de 5 semestres (3 años) en Unidades Curriculares, electivas, actividades adicionales e investigaciones, incluyendo la elaboración y defensa de la Tesis Doctoral, un tiempo máximo de 5 años como estudiante regular, y un año de prórroga para la presentación y defensa de su Tesis Doctoral (justificado)."
          />
        </Row>
        <Row>
           <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
           />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc ="Total U/C Doctorado: 60"
            color ="estructura-curricular-card-padre-blue"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Dra. Mariana Fernández",
                puesto:"Coordinadora del Doctorado",
                correo:""
              },
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default DoctoradoCienciasEducacionPage
