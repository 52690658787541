import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/doctorado_gerencia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const MaestriaEnDesarrolloDeOrganizacionesInteligentesPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fondo negro o blanco del título académico certificado por la universidad de la cual egresó."},
    {a:"Notas certificadas de pregrado certificado por la universidad de la cual egresó."},
    {a:"Currículo vitae simple."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carnet."},
    {a:"Fotocopia de la inscripción militar."},
  ]
  const semestres = [
    {
      semestre: "I Cuatrimestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Organizaciones Inteligentes', uc:2},
        {materia: 'Gerencia personal y formación de equipos ', uc:2},
      ]
    },
    { 
      semestre : 'II Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Sistemas de información', uc:2},
        {materia: 'Liderazgo transformacional', uc:2},
        {materia: 'Estrategias de crecimiento organizacional', uc:2},
      ]
    },
    { 
      semestre : 'III Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Seminario de investigación I: Epistemología de la investigación social', uc:3},
        {materia: 'Pensamiento estratégico en las organizaciones', uc:2},
        {materia: 'Electiva I', uc:2},
      ]
    },
    { 
      semestre : 'IV Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Seminario de investigación II: Proyecto del trabajo de grado', uc:2},
        {materia: 'Gerencia de los procesos comunicacionales en la organización', uc:2},
        {materia: 'Electiva II', uc:2},
      ]
    },
    { 
      semestre : 'V Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Estadística aplicada a la investigación', uc:3},
        {materia: 'Consultoría de procesos en las organizaciones', uc:2},
        {materia: 'Gerencia ambiental', uc:2},

      ]
    },
    { 
      semestre : 'VI Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Trabajo de Grado', uc:6},
      ]
    },
    { 
      semestre : 'Electiva I',
      uc :"U/C ",
      contenidos : [
        {materia: 'Gerencia del cambio en las organizaciones', uc:2},
        {materia: 'Psicología de las organizaciones', uc:2},
        {materia: 'Procesos culturales en las organizaciones', uc:2},

      ]
    },
  ]
  const competencias = [
    {a:"Reconocer la importancia de la consultoría de procesos centrada en la atención del capital humano de las organizaciones."},
    {a:"Elaborar el diagnóstico de los procesos y comportamientos humanos en la organización."},
    {a:"Poseer una actitud positiva ante los procesos de desarrollo humano y crecimiento personal en individuos y organizaciones."},
    {a:"Reconocer las competencias personales básicas para el desarrollo de organizaciones sanas e inteligentes."},
    {a:"Aplicar procedimientos, estrategias, técnicas y modelos que promueven y favorecen la creación de paradigmas exitosos en la organización."},
    {a:"Manejar la gestión del conocimiento como estrategia de acción para la construcción de organizaciones inteligentes."},
  ]

  return (
    <>
      <CarouselComponent
        title = "Magíster Scientiarum en Desarrollo de Organizaciones Inteligentes"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Promover el uso del conocimiento científico y la investigación para la atención, diagnóstico e intervención de los sistemas humanos en las organizaciones, a fin de contribuir con el desarrollo de organizaciones sanas, inteligentes y exitosas aportando valor agregado al sistema socio-productivo del país."
              ColorBorder="description-degree-green"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default MaestriaEnDesarrolloDeOrganizacionesInteligentesPage
