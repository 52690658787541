import React from 'react'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import {Container } from 'react-bootstrap'

const titlesGeneral = [
  {
    titulo: "Formación conducente a grados académicos",
    description: "Le ofrecemos las más variadas alternativas de estudios adaptadas a las necesidades de cada participante, promoviendo una formación académica de calidad, apoyada en las nuevas tecnologías.",
    green: 'description-degree',
  },
]

function TitleDegreeContainer() {
  return (
    <Container fluid className="d-flex justify-content-center"> 
        <TitleDegree
          titulo= {titlesGeneral[0].titulo}
          description ={titlesGeneral[0].description}
          ColorBorder={titlesGeneral[0].green}
        />
    </Container>
  )
}

export default TitleDegreeContainer
