import React from 'react'
import {
  Col,
  Container,
  Form,
  Row,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import TextInput from 'components/forms/text-input'
import SelectInput from 'components/forms/select-input'
import DateInput from 'components/forms/date-input'
import RadioInput from 'components/forms/radio-input'
import styles from './styles'

const userIcon = <FontAwesomeIcon icon={faUser} />

const genderOptions = [
  { value: 0, text: "Seleccione..." },
  { value: 1, text: "Masculino" },
  { value: 2, text: "Femenino" },
]

const PersonalInformation = () => {
    
  return (<>
    <Container style={styles.container}>
      <Row>
        <Col xs={12} style={styles.textContent}>
          <Form.Row>
            <TextInput fieldName="cedula" labelName="Cedula" icon={userIcon} />
            <TextInput fieldName="firstname" labelName="Primer Nombre" icon={userIcon} />
            <TextInput fieldName="secondname" labelName="Segundo Nombre" icon={userIcon} />
            <TextInput fieldName="lastname" labelName="Primer Apellido" icon={userIcon} />
            <TextInput fieldName="secondlastname" labelName="Segundo Apellido" icon={userIcon} />
            <SelectInput fieldName="gender_id" labelName="Género" options={genderOptions} icon={userIcon} />
            <DateInput fieldName="birthdate" labelName="Fecha de Nacimiento" icon={userIcon} />
            <TextInput fieldName="phone" labelName="Número telefónico" icon={userIcon} />
            <TextInput fieldName="email" labelName="Correo" icon={userIcon} />
            <TextInput fieldName="address" labelName="Dirección" icon={userIcon} />
            <RadioInput fieldName="foreign" labelName="¿Es usted foráneo?" icon={userIcon} />
            <RadioInput fieldName="disabled" labelName="¿Posee algún tipo de discapacidad?" icon={userIcon} />
          </Form.Row>
        </Col>
      </Row>
    </Container>
  </>)
}

export default PersonalInformation
