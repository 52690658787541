import * as Yup from 'yup'
import dateFnsFormat from 'date-fns/format'
import { formatDistance, subDays } from 'date-fns'

const FORMAT = 'dd/MM/yyyy';
const FILE_SIZE_MB = 20;
const FILE_SIZE = 1024 * 1024 * FILE_SIZE_MB; // 20 Megas es el tamaño máximo por archivo
const SUPPORTED_FORMATS = [
  "application/pdf",
];
const MAX_SENIOR_YEAR = 2021; // refactorizar por obtener el año desde el servidor

const fileSchema = Yup.mixed()
  .test(
    "fileSize",
    "El archivo es demasiado grande",
    value => {
      if(value === '' || value === undefined) {
        return true
      } else {
        return value && value.size <= FILE_SIZE
      }
    }
  )
  .test(
    "fileFormat",
    "El formato del archivo es incorrecto, debe ser PDF",
    value => {
      if(value === '' || value === undefined) {
        return true
      } else {
        return value && SUPPORTED_FORMATS.includes(value.type)
      }
    }
  )

export const schema = Yup.object({
  cedula: Yup.string().required("La cedula es requerida"),
  firstname: Yup.string().required("El primer nombre es requerido"),
  secondname: Yup.string(),
  lastname: Yup.string().required("El primer apellido es requerido"),
  secondlastname: Yup.string(),
  gender_id: Yup.number().required("El género es requerido")
    .positive("El número debe ser positivo")
    .min(1,"El género es requerido")
    .integer("El número debe ser un entero"),
  birthdate: Yup.string().required("La fecha es requerida"),
  phone: Yup.string().required("El número de teléfono es requerido"),
  email: Yup.string().email("Debe ser un correo válido").required("El correo es requerido"),
  address: Yup.string().required("La dirección es requerida"),
  foreign: Yup.boolean().required(),
  disabled: Yup.boolean().required(),
  country: Yup.string().required("El país es requerido"),
  state: Yup.string(),
  senior_year: Yup.number().required("El año de egreso es requerido").min(1900,"El año no puede ser menor a 1900").max(MAX_SENIOR_YEAR,`El año no puede ser mayor a ${MAX_SENIOR_YEAR}`),
  baccalaureate_degree: fileSchema,
  proof_baccalaureate_degree_and_certified_note: fileSchema,
  title_registry_copy: fileSchema,
  proof_documents_in_luz_file: fileSchema,
  birth_certificate: fileSchema,
  certified_birth_certificate: fileSchema,
  invoice: Yup.string(),
  book: Yup.string(),
  date_birth_certificate: Yup.string(),
  tome: Yup.string(),
  original_filial_data: fileSchema,
  gazette: fileSchema,
  proof_of_naturalization: fileSchema,
});

// birthdate: dateFnsFormat(new Date(), FORMAT),
export const initialValues = {
  cedula: '',
  firstname: '',
  secondname: '',
  lastname: '',
  secondlastname: '',
  gender_id: 0,
  birthdate: '',
  phone: '',
  email: '',
  address: '',
  foreign: false,
  disabled: false,
  country: '',
  state: '',
  senior_year: '',
  baccalaureate_degree: '',
  proof_baccalaureate_degree_and_certified_note: '',
  title_registry_copy: '',
  proof_documents_in_luz_file: '',
  birth_certificate: '',
  certified_birth_certificate: '',
  invoice: '',
  book: '',
  date_birth_certificate: '',
  tome: '',
  original_filial_data: '',
  gazette: '',
  proof_of_naturalization: '',
}

// export const initialValues = {
//   cedula: '123123',
//   firstname: 'Antonio',
//   secondname: 'Rafael',
//   lastname: 'Alvarez',
//   secondlastname: 'Perez',
//   gender_id: 0,
//   birthdate: '',
//   phone: '4564567',
//   email: 'antonio@mail.com',
//   address: 'Av 41 calle san martín',
//   foreign: false,
//   disabled: false,
//   country: 'Venezuela',
//   state: 'Zulia',
//   senior_year: '2019',
//   baccalaureate_degree: '',
//   proof_baccalaureate_degree_and_certified_note: '',
//   title_registry_copy: '',
//   proof_documents_in_luz_file: '',
//   birth_certificate: '',
//   certified_birth_certificate: '',
//   invoice: '',
//   book: '',
//   date_birth_certificate: '',
//   tome: '',
//   original_filial_data: '',
//   gazette: '',
//   proof_of_naturalization: '',
// }