import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/grados_academicos.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const EspecialidadTributacionesPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fotocopia de la cédula de identidad vigente"},
    {a:"Fondo blanco o negro del título universitario, certificado por la universidad donde egresó."},
    {a:"Notas originales obtenidas en pregrado o copias certificadas por la universidad de donde egresó."},
    {a:"Dos fotos tipo carné"},
    {a:"Síntesis curricular a una página."},
    {a:"Constancia de inscripción militar."},
    {a:"Partida de nacimiento original."},
  ]
  const semestres = [
    {
      semestre: "Nivelación",
      uc :"U/C ",
      contenidos : [
        {materia: 'Fundamentos de la economía.', uc:1},
        {materia: 'Contabilidad general.', uc:1},
        {materia: 'Introducción a la administración.', uc:1},
        {materia: 'Fundamentos de derecho público.', uc:1},
      ]
    },
    { 
      semestre : 'Formación Básica',
      uc :"U/C ",
      contenidos : [
        {materia: 'Estados y crisis fiscal.', uc:3},
        {materia: 'Legislación tributaria.', uc:3},
        {materia: 'Administración tributaria general.', uc:3},
        {materia: 'Metodología de la investigación.', uc:2},
      ]
    },
    { 
      semestre : 'Formación Profesional',
      uc :"U/C ",
      contenidos : [
        {materia: 'Impuestos sobre la renta y al capital.', uc:3},
        {materia: 'Impuestos sobre la producción, la circulación y el consumo.', uc:3},
        {materia: 'Tributación estadal y municipal.', uc:2},
        {materia: 'Tributación aduanera.', uc:3},
        {materia: 'Procedimiento administrativo tributario.', uc:2},
        {materia: 'Incumplimiento y las sanciones tributarias.', uc:2},
        {materia: 'Tributación internacional.', uc:2},
        {materia: 'Seminario taller de metodología.', uc:2},
      ]
    },
    { 
      semestre : 'Electivas',
      uc :"U/C ",
      contenidos : [
       {materia: 'Electivas', uc:2},
       {materia: 'Ajuste por inflación fiscal', uc:2},
       {materia: 'Auditoría tributaria.', uc:2},
       {materia: 'Procedimiento contencioso Tributario', uc:2},
       {materia: 'Planificación tributaria empresarial.', uc:2},
       {materia: 'Finanzas estatales y municipales', uc:2},
       {materia: 'TRABAJO ESPECIAL DE GRADO', uc:6},
      ]
    },
  ]
  const competencias = [
    {a:"Integración socio-regional con la finalidad de hacerle frente a los retos de la globalización y las sociedades"},
    {a:"Capacidad de determinar la cuantía o inexistencia de la obligación tributaria."},
    {a:"Dar cumplimiento los deberes formales y evitar los ilícitos tributarios, que originan sanciones pecuniarias y con penas restrictivas de la libertad."},
  ]

  return (
    <>
      <CarouselComponent
        title = "Especialización en Tributaciones"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Proporcionar los conocimientos teóricos de la imposición, que permitan explicar las tendencias actuales de la tributación global y analizar las experiencias del fenómeno tributario en los países."
              ColorBorder="description-degree-purple"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-purple"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Msc. Roberto García",
                puesto:"Coordinador de la Maestría",
                correo:""
              },
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default EspecialidadTributacionesPage
