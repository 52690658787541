import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { 
  Button,
  Col,
  Container,
  Form,
  Row,
  Alert,
} from 'react-bootstrap'
import Axios from 'axios';
import { API_URL } from '../../../../../../constants';

const styles = {
  container: {
    backgroundColor: "#fff",
    border: "1px solid #b4cbdb",
    borderRadius: "10px",
    fontSize: "14px"
  },
  alertText: {
    fontSize: "14px",
  },
}

const alertIdConsultContent = <>Estimado usuario ingresa tu número de cédula y presione el botón <b>Consultar inscripción</b> para verificar si usted fue inscrito para estudiar en la Universidad del Zulia.</>
const alertIdConsultPayroll = <>Para verificar sus datos ingese su número de planilla en el segundo campo y presione en <b>Consultar planilla</b>.</>

const CedulaForm = () => {
  const [cedula, setCedula] = useState("")
  const [alertText, setAlertText] = useState("")
  const [alertVariant, setAlertVariant] = useState("danger")
  const [showAlert, setShowAlert] = useState(false)

  const [payroll, setPayroll] = useState("")
  const [alertTextPayroll, setAlertTextPayroll] = useState("")
  const [alertVariantPayroll, setAlertVariantPayroll] = useState("danger")
  const [showAlertPayroll, setShowAlertPayroll] = useState(false)

  const consultingInscription = () => {
    Axios.get(`${API_URL}/students/cedula/${cedula}`)
      .then(response => {
        console.log(response);
        setAlertText(response.data.message)
        setAlertVariant("success")
        setShowAlert(true)
      }).catch(error => {
        console.log(error)
        const err = Object.assign({}, error)
        console.log(err)
        if (err.response.status === 404) {
          setAlertText(err.response.data.message)
          setAlertVariant("danger")
          setShowAlert(true)
        } else {
          setAlertText("Ha ocurrido un error al realizar la cosnulta")
          setAlertVariant("danger")
          setShowAlert(true)
        }
      })
  }

  const consultingPayroll = () => {
    Axios.get(`${API_URL}/payroll/${payroll}`)
      .then(response => {
        setAlertTextPayroll(<>Planilla encontrada: <NavLink to={`/inscripcion/planilla/${response.data.payroll_number}`}>Ver planilla</NavLink></>)
        setAlertVariantPayroll("success")
        setShowAlertPayroll(true)
      }).catch(error => {
        console.log(error)
        const err = Object.assign({}, error)
        console.log(err)
        if (err.response.status === 404) {
          setAlertTextPayroll(err.response.data.message)
          setAlertVariantPayroll("danger")
          setShowAlertPayroll(true)
        } else {
          setAlertTextPayroll("Ha ocurrido un error al realizar la cosnulta")
          setAlertVariantPayroll("danger")
          setShowAlertPayroll(true)
        }
      })
  }
  return (
      <Container style={styles.container}>
        <Row>
          <Col xs={12}>
            <Form.Group as={Row} controlId="formID">
              <Col xs={12} md={6} className="pt-3 d-flex flex-column justify-content-center">
                <Form.Label>Cédula</Form.Label>
                <div>
                  <Form.Control type="number" 
                    onChange={e => {
                      setCedula(e.target.value)
                      setShowAlert(false)
                    }} 
                    placeholder="Ejemplo: 30836293" 
                  />
                  <Button className="mt-2" size="sm"
                    onClick={() => {
                      consultingInscription()
                    }}
                    disabled={cedula === ""}
                  >Consultar inscripción</Button>
                </div>
                { showAlert && (<Alert variant={alertVariant} className="mt-2 text-center" style={{fontSize: "14px"}}>{alertText}</Alert>)}
                <Form.Label className="mt-3">Número de planilla</Form.Label>
                <div>
                  <Form.Control 
                    type="number" placeholder="Ejemplo: 14330836293786" 
                      onChange={e => {
                        setPayroll(e.target.value)
                        setShowAlertPayroll(false)
                      }}
                  />
                  <Button variant="info" className="mt-2 text-white" size="sm"
                    onClick={() => {
                      consultingPayroll()
                    }}
                    disabled={payroll === ""}
                  >Consultar planilla</Button>
                </div>
                { showAlertPayroll && (<Alert variant={alertVariantPayroll} className="mt-2 text-center" style={{fontSize: "14px"}}>{alertTextPayroll}</Alert>)}
              </Col>
              <Col xs={12} md={6} className="pt-3">
                <Alert variant="primary" style={styles.alertText}>{alertIdConsultContent}</Alert>
                <Alert variant="primary" style={styles.alertText}>{alertIdConsultPayroll}</Alert>
                <Alert variant="primary" style={styles.alertText}>
                  Para iniciar su proceso de inscripción en la Universidad del Zulia presione en
                  {" "}<Button as={NavLink} to="/inscripcion/solicitud-de-datos" variant="success" size="sm">Comenzar</Button>
                </Alert>
              </Col>
            </Form.Group>
          </Col>
        </Row>
      </Container>
  )
}

export default CedulaForm
