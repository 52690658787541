import { useState, useEffect } from 'react'
import Axios from 'axios'
import { API_URL } from '../../../../constants'

const useRegistered = () => {
  const [registered, setRegistered] = useState([])

  useEffect(() => {
    let token = localStorage.getItem('token');
    Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    Axios.defaults.headers.common['Content-Type'] = "application/json";
    Axios.get(`${API_URL}/postgraduateCensu`)
      .then(response => {
        console.log(response);
        setRegistered(response.data)
      }).catch(error => {
        console.log(error)
        const err = Object.assign({}, error)
        console.log(err)
        setRegistered([])
      })
  },[])

  return [registered, setRegistered];
}

export default useRegistered