import React from 'react'
import {
  Col,
  Form,
  InputGroup,
} from 'react-bootstrap'

const ShowData = ({ name, value, icon, sizes, ...props }) => {
  return (
    <Form.Group as={Col} md="4" {...sizes} controlId={`validationFormik-${name}`}>
      <Form.Label>{name}</Form.Label>
      <InputGroup size="sm">
        { icon && (
        <InputGroup.Prepend>
          <InputGroup.Text id="basic-addon1" className="bg-primary text-white">{icon}</InputGroup.Text>
        </InputGroup.Prepend>)}
        <Form.Control
          type="text"
          style={{ backgroundColor: "white" }}
          name={name}
          onClick={e => e.currentTarget.blur()}
          value={value}
          readOnly
          {...props}
        />
      </InputGroup>
    </Form.Group>
  )
}

export default ShowData
