import React from 'react'
import {
  // Button,
  Carousel,
} from 'react-bootstrap'
// import { NavLink } from 'react-router-dom'


const CarouselComponent = (props) => {
  return (
    <Carousel className="carousel-programa">
        <Carousel.Item>
          <div className="d-block w-100" style={{backgroundColor: "#00000088", height: "100%", position: "absolute"}}>
          </div>
          <img
            className="d-block w-100"
            src={props.image}
            alt="First slide"
          />
          <Carousel.Caption>
            <h1 className="d-block">{props.title}</h1>
            <h5>{props.description}</h5>
          </Carousel.Caption>
        </Carousel.Item>
    </Carousel>
  )
}

export default CarouselComponent
