import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons'
import logo from 'img/logo_luz_circular.png'
import { Spinner, Alert } from 'react-bootstrap'

import { useUserDispatch, loginUser } from "context/user-context";

const LoginAdmin = (props) => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var userDispatch = useUserDispatch();
  return (
    <div className="client-login">
      <div className="container h-100">
        <div className="d-flex justify-content-center h-100">
          <div className="user_card">
            <div className="d-flex justify-content-center">
              <div className="brand_logo_container">
                <img src={logo} className="brand_logo" alt="Logo" />
              </div>
            </div>
            <div className="d-flex justify-content-center form_container">
              <form onSubmit={e => {
                e.preventDefault()
                loginUser(
                  userDispatch,
                  email,
                  password,
                  props.history,
                  setIsLoading,
                  setError
                )
              }}>
                <div className="input-group mb-3">
                  <div className="input-group-append">
                    <span className="input-group-text"><FontAwesomeIcon icon={faUser} /></span>
                  </div>
                  <input type="text" className="form-control input_user" value={email} placeholder="correo" onChange={e => {
                    setEmail(e.target.value)
                    setError(false)
                  }}  />
                </div>
                <div className="input-group mb-2">
                  <div className="input-group-append">
                    <span className="input-group-text"><FontAwesomeIcon icon={faLock} /></span>
                  </div>
                  <input type="password" className="form-control input_pass" value={password} placeholder="contraseña" onChange={e => {
                    setPassword(e.target.value)
                    setError(false)
                  }}  />
                </div>
                <div className="form-group">
                  <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="customControlInline" />
                    <label className="custom-control-label" htmlFor="customControlInline">Recordarme</label>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3 login_container">
                  <button type="submit" name="button" className="btn login_btn"
                    disabled={isLoading}
                  >
                  { isLoading ? (<>
                    <Spinner className="mr-2" size="sm" animation="border" variant="primary" />
                    Accediendo
                  </>) : (<>Acceder</>)}
                  </button>
                </div>
              </form>
            </div>
            { error && (<Alert variant="danger" className="mt-2 text-center" style={{fontSize: "14px"}}>El usuario o la contraseña son incorrectos</Alert>)}
            {/* <div className="mt-4">
              <div className="d-flex justify-content-center links">
                <a href="#">¿Has olvidado tu contraseña?</a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginAdmin
