import React, { Fragment } from 'react'
import { Col, Container, Row } from 'react-bootstrap'



const AutoridadesUniversitarias = (props) => {
  return (
    <>
      <Container fluid className="autoridades-universitarias">
        <Row>
          <Col>
            <h1 className="autoridades-universitarias-title">
              AUTORIDADES UNIVERSITARIAS
            </h1>
            {props.Autoridades.map(persona => {
              const element = (<><h5 className="autoridades-universitarias-nombre" >{persona.nombre}</h5>
              <p className="autoridades-universitarias-puesto">{persona.puesto}</p></>)
              const divider = <hr></hr>
              return (<Fragment key={persona.nombre + persona.puesto}>
                {persona.nombre.includes("Calixto Hernández") ? divider : ""}
                {element}
              </Fragment>)
            })}
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default AutoridadesUniversitarias
