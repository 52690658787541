import React from 'react'
import { withRouter } from 'react-router-dom';
import {
  Alert,
  Container,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'

import Band from '../../components/band'
import Header from '../../components/header'
import CedulaForm from './components/cedulaForm'
import Instructions from './components/instructions'

const userIcon = <FontAwesomeIcon icon={faUser} />
const helpIcon = <FontAwesomeIcon icon={faQuestionCircle} />

const styles = {
  alertText: {
    fontSize: "14px",
    textTransform: "uppercase",
  },
  container: {
    maxWidth: "940px",
    margin: "0 auto",
  },
  global: {
    backgroundColor: "rgb(232, 240, 245)",
  },
}

const alertBeforeStartContent = <><b>Antes de comenzar lea detenidamente las instrucciones que aparecen debajo</b></>


function Inscription() {
  return (<>
    <Container fluid style={styles.global}>
      <Container style={styles.container}>
        <Container>
          <Header />
          <div className="mt-3"><Band icon={userIcon} text="Inscripción" /></div>
          <div className="mt-2">
            <Alert variant="danger" style={styles.alertText}>{alertBeforeStartContent}</Alert>
          </div>
          <div className="mt-2"><CedulaForm /></div>
          <div className="mt-3"><Band icon={helpIcon} text="Instrucciones" /></div>
          <div className="mt-2"><Instructions /></div>
        </Container>
      </Container>
    </Container>
  </>)
}

export default withRouter(Inscription);