import React from 'react'
import BannerImage from "./banner.png"

const styles = {
  container: {
    maxWidth: "940px",
    margin: "0 auto",
  }
}

export default () => {
  return (
    <div style={styles.container}>
      <img
        src={BannerImage}
        width="100%"
        alt="Banner.jpg"
      />
    </div>
  )
}