import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import { 
  Button,
  Col,
  Container,
  Form,
  Row,
  Alert,
  Card,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons'

import ShowData from 'components/show-data'
import ShowFile from 'components/show-file'

const userIcon = <FontAwesomeIcon icon={faUser} />

const styles = {
  container: {
    backgroundColor: "#fff",
    border: "1px solid #b4cbdb",
    borderRadius: "10px",
    fontSize: "14px",
    color: "rgb(47, 80, 100)",
    position: "relative",
  },
  alertText: {
    fontSize: "14px",
  },
}


const CedulaForm = ({ student }) => {
  return (<>
      <Container style={styles.container}>
        <Row>
          <Col xs={12} className="pt-3" >
            { student.cedula === '' ? 
              (<h5>Cargando...</h5>) :
              (<h5>N° de planilla: {student.payroll_number}</h5>)
            }
          </Col>
          <Col xs={12}>
            <Form.Row>
              <ShowData name="Cedula" value={student.cedula} icon={userIcon} />
              <ShowData name="Primer Nombre" value={student.firstname} icon={userIcon} />
              {student.secondname !== '' && student.secondname !== undefined && student.secondname !== null && (
                <ShowData name="Segundo Nombre" value={student.secondname} icon={userIcon} />
              )}
              <ShowData name="Primer Apellido" value={student.lastname} icon={userIcon} />
              {student.secondlastname !== '' && student.secondlastname !== undefined && student.secondlastname !== null && (
                <ShowData name="Segundo Apellido" value={student.secondlastname} icon={userIcon} />
              )}
              <ShowData name="Género" value={student.gender_id === 1 ? "Masculino" : "Femenino"} icon={userIcon} />
              <ShowData name="Fecha de Nacimiento" value={student.birthdate !== '' ? student.birthdate.substring(8, 10) + "-" + student.birthdate.substring(5, 7) + "-" + student.birthdate.substring(0, 4) : ''} icon={userIcon} />
              <ShowData name="Número telefónico" value={student.phone} icon={userIcon} />
              <ShowData name="Correo" value={student.email} icon={userIcon} />
              <ShowData name="Dirección" value={student.address} icon={userIcon} />
              <ShowData name="Foráneo" value={student.foreign === true ? "SI" : "NO"} icon={userIcon} />
              <ShowData name="Discapacidad" value={student.disabled === true ? "SI" : "NO"} icon={userIcon} />
              <ShowData name="País donde estudió" value={student.country} icon={userIcon} />
              {student.state !== '' && student.state !== undefined && student.state !== null && (
                <ShowData name="Estado donde estudió" value={student.state} icon={userIcon} />
              )}
              <ShowData name="Año de egreso" value={student.senior_year} icon={userIcon} />
              {student.baccalaureate_degree !== '' && student.baccalaureate_degree !== undefined && student.baccalaureate_degree !== null && (
                <ShowFile sizes={{md: 6}} name="Título de bachiller" value={student.baccalaureate_degree} />
              )}
              {student.proof_baccalaureate_degree_and_certified_note !== '' && student.proof_baccalaureate_degree_and_certified_note !== undefined && student.proof_baccalaureate_degree_and_certified_note !== null && (
                <ShowFile sizes={{md: 6}} name="Constancia de tramitación del título de bachiller + notas certificadas originales" value={student.proof_baccalaureate_degree_and_certified_note} />
              )}
              {student.title_registry_copy !== '' && student.title_registry_copy !== undefined && student.title_registry_copy !== null && (
                <ShowFile sizes={{md: 6}} name="Copia Certificada del Registro del Título" value={student.title_registry_copy} />
              )}
              {student.proof_documents_in_luz_file !== '' && student.proof_documents_in_luz_file !== undefined && student.proof_documents_in_luz_file !== null && (
                <ShowFile sizes={{md: 6}} name="Constancia de documentos en el archivo de LUZ" value={student.proof_documents_in_luz_file} />
              )}
              {student.birth_certificate !== '' && student.birth_certificate !== undefined && student.birth_certificate !== null && (
                <ShowFile sizes={{md: 6}} name="Partida de nacimiento" value={student.birth_certificate} />
              )}
              {student.certified_birth_certificate !== '' && student.certified_birth_certificate !== undefined && student.certified_birth_certificate !== null && (
                <ShowFile sizes={{md: 6}} name="Copia del libro o partida de nacimiento certificada" value={student.certified_birth_certificate} />
              )}
              {student.invoice !== '' && student.invoice !== undefined && student.invoice !== null && (
                <ShowData sizes={{md: 6}} name="Folio" value={student.invoice} icon={userIcon} />
              )}
              {student.book !== '' && student.book !== undefined && student.book !== null && (
                <ShowData sizes={{md: 6}} name="Libro" value={student.book} icon={userIcon} />
              )}
              {student.date_birth_certificate !== '' && student.date_birth_certificate !== undefined && student.date_birth_certificate !== null && (
                <ShowData sizes={{md: 6}} name="Fecha" value={student.date_birth_certificate !== '' ? student.date_birth_certificate.substring(8, 10) + "-" + student.date_birth_certificate.substring(5, 7) + "-" + student.date_birth_certificate.substring(0, 4) : ''} icon={userIcon} />
              )}
              {student.tome !== '' && student.tome !== undefined && student.tome !== null && (
                <ShowData sizes={{md: 6}} name="Tomo" value={student.tome} icon={userIcon} />
              )}
              {student.original_filial_data !== '' && student.original_filial_data !== undefined && student.original_filial_data !== null && (
                <ShowFile sizes={{md: 6}} name="Datos filiatorios en original" value={student.original_filial_data} />
              )}
              {student.gazette !== '' && student.gazette !== undefined && student.gazette !== null && (
                <ShowFile sizes={{md: 6}} name="Gaceta" value={student.gazette} />
              )}
              {student.proof_of_naturalization !== '' && student.proof_of_naturalization !== undefined && student.proof_of_naturalization !== null && (
                <ShowFile sizes={{md: 6}} name="Constancia de naturalización" value={student.proof_of_naturalization} />
              )}
            </Form.Row>
          </Col>
        </Row>
      </Container>
  </>)
}

export default CedulaForm
