import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const EstructuraCurricular = (props) => {
  return (
    <>
      <Container  fluid className="estructura-curricular">     
        <h1>Diseño curricular</h1>  
      <Row className="estructura-curricular-row">
        {props.semestres.map((semestre,semestreIndex) =>{
          const contenidos = semestre.contenidos
          return (
            <Col xs={12} sm={12} md={12} lg={8} xl={8} className="estructura-curricular-column" key={semestreIndex} >
              <div className={`card mt-3 ${props.color}`}>
                <div className="estructura-curricular-card-body" >
                  <div className="estructura-curricular-card-materiaTitulo">
                    {semestre.semestre}
                  </div>
                  <div className="estructura-curricular-card-ucTitulo ">
                      {semestre.uc}
                  </div>
                </div>
              </div>
              {contenidos.map((contenido, contenidoIndex)=>(
                <div className="card estructura-curricular-card-padre" key={contenidoIndex}>
                <div className="estructura-curricular-card-body" >
                  <div className="estructura-curricular-card-materia">
                    {contenido.materia}
                  </div>
                  <div className="estructura-curricular-card-uc">
                      {contenido.uc}
                  </div>
                </div>
              </div>
              ))}
          </Col>
          )}
        )}
          <Col xs={12} sm={12} md={12} lg={12} xl={12} className="estructura-curricular-column mt-2" >
              <h5 className="font-weight-bold">{props.totaluc}</h5>
          </Col>
      </Row>  
      </Container>

      
      {/* <Container fluid className="estructura-curricular-mobile">

        <div className="ancestros">

        {props.semestres.map((se)=>{
          const contenidos = se.contenidos
          console.log(contenidos)
          return (
            <div className="abuelo">

              <div className="padre">
                <div className="hijo">{se.semestre}</div>
                <div className="hijo">{se.uc}</div>
              </div>

              {contenidos.map((co)=>(
              <div className="padreHermano">
                  {co.materia}
              </div>
              ))}

            </div>
          )
        })}
        
        </div>
      </Container> */}
      
    </>
  )
}

export default EstructuraCurricular
