import React from "react";
import axios from "axios";
import jwt from "jsonwebtoken";

import { API_URL } from "../../constants"

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state };
    case "SIGN_OUT_SUCCESS":
      return { ...state };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: () => {
      const token = localStorage.getItem("token")
      if (token) {
        const date = new Date().getTime() / 1000;
        const data = jwt.decode(token);
        return date < data.exp;
      } else if (token) {
        return true
      }
      return false;
    }
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  social = ""
) {
  setError(false);
  setIsLoading(true);
  
  axios
    .post(`${API_URL}/login`, { email: login, password })
    .then(res => {
      console.log(res)
      const token = res.data.access_token;
      setTimeout(() => {
        setError(null);
        setIsLoading(false);
        receiveToken(token, dispatch);
        history.push("/postgrado/censo/admin");
      }, 2000);
    })
    .catch(error => {
      setError(true);
      setIsLoading(false);

      const err = Object.assign({}, error);
      console.log(error);
      console.log(err);
    });
}

function signOut(dispatch, history) {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  axios.defaults.headers.common["Authorization"] = "";
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push("/");
}

export function receiveToken(token, dispatch) {
  localStorage.setItem("token", token);
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
  dispatch({ type: "LOGIN_SUCCESS" });
}
