import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const CompetenciasDelEgresado = (props) => {
  return (
    <Container  fluid className="competencias-del-egresado ">
    <Row className="competencias-del-egresado-row">
      <Col xs={12} sm={12} md={12} lg={10} xl={8} className="competencias-del-egresado-col" >
        <h1 className="competencias-del-egresado-titulo">{props.title}</h1>
        <ul className="competencias-del-egresado-parrafo">
          {props.description.map((e,key)=> (<li key={key}>{e.a}</li>))}
        </ul>   
      </Col>
    </Row>
  </Container>
  )
}

export default CompetenciasDelEgresado
