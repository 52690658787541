import React from 'react'
import { useFormikContext } from 'formik'
import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
  faUserAlt,
  faScroll,
  faPhone,
  faAt,
  faGlobeAmericas,
  faHouseUser
} from '@fortawesome/free-solid-svg-icons'

import Band from '../../../../components/band'
import TextInput from 'components/forms/text-input'
import SelectInput from 'components/forms/select-input'
import styles from './styles'
import countries from './countries'
import specialties from './specialties'

const userIcon = <FontAwesomeIcon icon={faUser} />
const userAltIcon = <FontAwesomeIcon icon={faUserAlt} />
const scrollIcon = <FontAwesomeIcon icon={faScroll} />
const phoneIcon = <FontAwesomeIcon icon={faPhone} />
const atIcon = <FontAwesomeIcon icon={faAt} />
const globeAmericasIcon = <FontAwesomeIcon icon={faGlobeAmericas} />
const houseUserIcon = <FontAwesomeIcon icon={faHouseUser} />

const StudentForm = ({ loadingForm, setLoadingForm, residenceId, residenceOptions }) => {
  const { submitForm } = useFormikContext()
  return (
    <Form noValidate onSubmit={e => {
      e.preventDefault()
      setLoadingForm(true)
      submitForm()
    }}>
      <div className="mt-3"><Band icon={userIcon} text="Censo de maestrías y doctorados" /></div>
      <Container style={styles.container}>
        <Row>
          <Col xs={12} style={styles.textContent}>
            <Form.Row>
              <TextInput fieldName="names" labelName="Nombres" icon={userIcon} />
              <TextInput fieldName="lastnames" labelName="Apellidos" icon={userAltIcon} />
              <SelectInput fieldName="specialty_id" labelName="Especialidad" options={specialties} icon={scrollIcon} />
              <TextInput fieldName="phone" labelName="Teléfono" icon={phoneIcon} />
              <TextInput fieldName="email" labelName="Correo" icon={atIcon} />
              <SelectInput fieldName="country" labelName="País" options={countries} icon={globeAmericasIcon} />
              <Form.Group as={Col} md="4" controlId={`validationFormik-residence`}>
                <Form.Label>Residencia</Form.Label>
                <InputGroup size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1" className="bg-primary text-white">{houseUserIcon}</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    as="select"
                    disabled
                  >
                    {residenceOptions.map(residenceOption => (
                      <option
                        key={residenceOption.id}
                        value={residenceOption.id}
                        selected={residenceOption.id === residenceId}
                      >{residenceOption.name}</option>
                    ))}
                  </Form.Control>
                </InputGroup>
              </Form.Group>
            </Form.Row>
          </Col>
        </Row>
        <Row>
          <Col xs="12" className="text-right d-none d-md-block">
            <Button size="sm" type="submit"
              disabled={loadingForm}
            >
              {loadingForm ? 
                (<Spinner animation="border" size="sm" variant="light" />)
                :
                ""
              }
              {' '}Censarme
            </Button>
          </Col>
          <Col xs="12" className="d-md-none d-block">
            <Button size="sm" type="submit" block
              disabled={loadingForm}
            >
              {loadingForm ? 
                (<Spinner animation="border" size="sm" variant="light" />)
                :
                ""
              }
              {' '}Censarme
            </Button>
          </Col>
        </Row>
      </Container>
      </Form>
  )
}

export default StudentForm
