import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/cursos-para-empresas.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const MaestriaGerenciaInnovacionPage = ({ registerState }) => {
  const requisitos = [
    {a:"Poseer Título como profesional universitario, egresado de las áreas asociadas a las Ciencias, ingeniería y tecnología."},
    {a:"Fotografía en fondo negro o fondo blanco certificado del título académico."},
    {a:"Notas certificadas de pregrado."},
    {a:"Currículo vitae."},
    {a:"Demostrar suficiencia en el idioma inglés instrumental (a través de prueba específica o aprobar curso)."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carné."},
    {a:"Fotocopia de la inscripción militar."},
    {a:"Formalizar preinscripción."},
    {a:"Realizar entrevista con el coordinador del programa para obtener la aprobación de ingreso al programa y recepción de requisitos de ingreso."},
    {a:"Cancelar la matricula reglamentaria."},

  ]
  const semestres = [
    {
      semestre: "I Semestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Sistemas de Innovación', uc:3},
        {materia: 'Políticas Públicas en el Ámbito de la Ciencia y la Tecnología', uc:2},
        {materia: 'Seminario de Investigación I: Epistemología de la Innovación', uc:2},
      ]
    },
    { 
      semestre : 'II Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Transferencia Tecnológica', uc:3},
        {materia: 'Gerencia de Proyectos de Investigación y Desarrollo', uc:2},
        {materia: 'Electiva I', uc:2},
        {materia: 'Seminario de Investigación II: Ética de la Investigación', uc:2},
      ]
    },
    { 
      semestre : 'III Semestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Gestión de la Innovación ', uc:3},
        {materia: 'Electiva II', uc:2},
        {materia: 'Seminario de Investigación III: La investigación al servicio del sector social', uc:2},
      ]
    },
    { 
      semestre : 'IV Semestre',
      uc :"U/C ",
      contenidos : [
       {materia: 'Teoría de la Innovación Tecnológica', uc:3},
       {materia: 'Indicadores de Productividad Científica y Tecnológica', uc:2},
       {materia: 'Seminario de Investigación IV: Uso de técnicas estadísticas', uc:2},
       {materia: 'TRABAJO DE GRADO', uc:6},

      ]
    },
    { 
      semestre : 'Electivas',
      uc :"U/C ",
      contenidos : [
       {materia: 'Gestión de la Investigación y Desarrollo.', uc:2},
       {materia: 'Gestión del Conocimiento.', uc:2},
       {materia: 'Formulación y Evaluación de Proyectos.', uc:2},
       {materia: 'Valoración y Negociación de la Tecnología.', uc:2},
       {materia: 'Gerencia de Riesgo Tecnológico.', uc:2},
       {materia: 'Gestión Estratégica de la Innovación.', uc:2},
       {materia: 'Cultura Innovativa y Responsabilidad Social.', uc:2},
       {materia: 'Propiedad Intelectual.', uc:2},
      ]
    },
  ]
  const competencias = [
    {a:"Tener dominio y competencia para la formulación y solución creativa de problemas y retos."},
    {a:"Diseñar, implementar y desarrollar la gestión de procesos de innovación en Pymes, empresas, e institucionales."},
    {a:"Desarrollar habilidades a través de la apropiación y aplicación de conocimientos, metodologías y herramientas de gestión."},
    {a:"Formular políticas científico-tecnológicas, para fortalecer el ordenamiento y análisis de la numerosa información tecnológica dispersa que apoye la generación de la innovación."},
    {a:"Fortalecer iniciativas de desarrollo productivo nacional y regional para lograr el fortalecimiento tecnológico, que permita la generación de empresas de base tecnológica. "},

  ]

  return (
    <>
      <CarouselComponent
        title = "Magíster Scientiarum en Gerencia de la Innovación"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="Desarrollar competencias en los participantes  para estimular la  competitividad en los sectores productivos, educativos, y de investigación, con el propósito de fortalecer la gerencia de la innovación en el país y así promover las capacidades de innovación necesarias para el fortalecimiento del sistema socio productivo."
              ColorBorder="description-degree-green"

            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "La Maestría en Gerencia de la Innovación comprende cuatro (4) semestres de escolaridad (02 años) y un año (1) para la elaboración del Trabajo de Grado."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
              {
                nombre:"Dra. Dioneira Miquilena",
                puesto:"Coordinadora de la Maestría",
                correo:""
              },
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default MaestriaGerenciaInnovacionPage
