import React, { useState } from 'react'
import {
  Col,
  Form,
  Row,
} from 'react-bootstrap'
import { useFormikContext } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faUser,
} from '@fortawesome/free-solid-svg-icons'

import BadgeWrapper from "pages/undergraduate/components/wrapper/badge"
import TextInput from 'components/forms/text-input'
import DateInput from 'components/forms/date-input'
import FileInput from 'components/forms/file-input'
import styles from '../../styles'

const userIcon = <FontAwesomeIcon icon={faUser} />

const degreeColOptions = [
  {
    letter: 'b',
    setFieldValues: [
      { fieldName: "baccalaureate_degree", value: '' },
      { fieldName: "title_registry_copy", value: '' },
      { fieldName: "proof_documents_in_luz_file", value: '' },
    ],
    badgeText: "Constancia de tramitación del título de bachiller + notas certificadas originales:",
    fileName: "proof_baccalaureate_degree_and_certified_note",
    fileTextHelp: "",
  },
  {
    letter: 'c',
    setFieldValues: [
      { fieldName: "baccalaureate_degree", value: '' },
      { fieldName: "proof_baccalaureate_degree_and_certified_note", value: '' },
      { fieldName: "proof_documents_in_luz_file", value: '' },
    ],
    badgeText: "Constancia de tramitación del título de bachiller + notas certificadas originales:",
    fileName: "title_registry_copy",
    fileTextHelp: "",
  },
  {
    letter: 'd',
    setFieldValues: [
      { fieldName: "baccalaureate_degree", value: '' },
      { fieldName: "proof_baccalaureate_degree_and_certified_note", value: '' },
      { fieldName: "title_registry_copy", value: '' },
    ],
    badgeText: "Constancia de tramitación del título de bachiller + notas certificadas originales:",
    fileName: "proof_documents_in_luz_file",
    fileTextHelp: "",
  },
]

const birthCertificateColOptions = [
  {
    letter: 'c',
    setFieldValues: [
      { fieldName: "birth_certificate", value: '' },
      { fieldName: "certified_birth_certificate", value: '' },
      { fieldName: "invoice", value: '' },
      { fieldName: "book", value: '' },
      { fieldName: "date_birth_certificate", value: '' },
      { fieldName: "tome", value: '' },
      { fieldName: "original_filial_data", value: '' },
      { fieldName: "gazette", value: '' },
      { fieldName: "proof_of_naturalization", value: '' },
    ],
    badgeText: "Datos filiatorios en original:",
    fileName: "original_filial_data",
    fileTextHelp: "si eres venezolano(a) cedulado(a) y no posees partida de nacimiento por deterioro, extravío o problemas en la prefectura y el registro, puedes consignar una certificación de datos filiatorios emitida por el Servicio Administrativo de Identificación, Migración y Extranjería (SAIME).",
  },
  {
    letter: 'd',
    setFieldValues: [
      { fieldName: "birth_certificate", value: '' },
      { fieldName: "certified_birth_certificate", value: '' },
      { fieldName: "invoice", value: '' },
      { fieldName: "book", value: '' },
      { fieldName: "date_birth_certificate", value: '' },
      { fieldName: "tome", value: '' },
      { fieldName: "original_filial_data", value: '' },
      { fieldName: "proof_of_naturalization", value: '' },
    ],
    badgeText: "Gaceta:",
    fileName: "gazette",
    fileTextHelp: "De ser extranjero nacionalizado, puedes presentar la Gaceta Oficial que acredite la nacionalidad venezolana.",
  },
  {
    letter: 'e',
    setFieldValues: [
      { fieldName: "birth_certificate", value: '' },
      { fieldName: "certified_birth_certificate", value: '' },
      { fieldName: "invoice", value: '' },
      { fieldName: "book", value: '' },
      { fieldName: "date_birth_certificate", value: '' },
      { fieldName: "tome", value: '' },
      { fieldName: "original_filial_data", value: '' },
      { fieldName: "gazette", value: '' },
    ],
    badgeText: "Constancia de naturalización:",
    fileName: "proof_of_naturalization",
    fileTextHelp: "Documento que certifica ante las autoridades competentes el hecho que un(a) ciudadano(a)extranjero(a) ha adquirido la nacionalidad venezolana, a través de la naturalización.",
  },
]


const BaccalaureateDegree = () => {
  const { values, setFieldValue } = useFormikContext()
  const [radioBacDegree, setRadioBacDegree] = useState("radioBacDegree1")
  const [radioBirth, setRadioBirth] = useState("radioBirth1")
  return (
    <Col xs={12} style={styles.textContent}>
      <Form.Row>
        <Col xs="12">
          <BadgeWrapper variant="info" style={styles.badgeNumber}>1</BadgeWrapper>
          <BadgeWrapper variant="primary">Título de bachiller:</BadgeWrapper>
        </Col>
        <Col xs="12" className="d-md-flex justify-content-md-center">
          <Form.Check type={"radio"} custom id={`radio-degree-1`}>
            <Form.Check.Input type={"radio"} name="radio-degree" value="radioBacDegree1" 
              checked={radioBacDegree === "radioBacDegree1"}
              onChange={e => setRadioBacDegree(e.currentTarget.value)}
              onClick={() => {
                setFieldValue("title_registry_copy", '')
                setFieldValue("proof_documents_in_luz_file", '')
                setFieldValue("proof_baccalaureate_degree_and_certified_note", '')

                document.getElementById(`validationFormik-title_registry_copy`).value = ""
                document.getElementById(`validationFormik-proof_documents_in_luz_file`).value = ""
                document.getElementById(`validationFormik-proof_baccalaureate_degree_and_certified_note`).value = ""
              }}
            />
            <Form.Check.Label>
              <div style={{ transform: "translateY(2px)" }}>
                <BadgeWrapper variant="info" style={styles.badgeNumber}>a</BadgeWrapper>
                <BadgeWrapper variant="primary">Título de bachiller:</BadgeWrapper>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col md="4"></Col>
        <FileInput fieldName="baccalaureate_degree" labelName="" 
          sizes={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
          disabled={radioBacDegree !== "radioBacDegree1"}
          textHelp={(<>Si <b>NO POSEES</b> tu título original lee cuidadosamente las opciones y selecciona los documentos que consignarás.</>)}
        />
      </Form.Row>
      <Form.Row>
        {degreeColOptions.map((section, index) => (<Col md="4" key={index} style={styles.textContent}>
          <Row>
            <Col xs="12">
              <Form.Check type={"radio"} custom id={`radio-degree-${index+2}`}>
                <Form.Check.Input type={"radio"} name="radio-degree" value={`radioBacDegree${index+2}`}
                  checked={radioBacDegree === `radioBacDegree${index+2}`}
                  onChange={e => setRadioBacDegree(e.currentTarget.value)}
                  onClick={() => {
                    section.setFieldValues.forEach(item => {
                        setFieldValue(item.fieldName, item.value)
                      })
                      section.setFieldValues.forEach(item => {
                        document.getElementById(`validationFormik-${item.fieldName}`).value = ""
                      })
                    }}
                />
                <Form.Check.Label>
                  <div className="d-flex" style={{ transform: "translateY(2px)" }}>
                    <div className="pr-1">
                      <BadgeWrapper variant="info" style={styles.badgeNumber}>{section.letter}</BadgeWrapper>
                    </div>
                    <div>
                      { section.badgeText && (<BadgeWrapper variant="primary" style={{ whiteSpace: "normal", textAlign: "left" }}>
                        {section.badgeText}
                      </BadgeWrapper>)}
                    </div>
                  </div>
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md="12">
              <FileInput fieldName={section.fileName} labelName="" 
                disabled={radioBacDegree !== `radioBacDegree${index+2}`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                textHelp={section.fileTextHelp}
              />
            </Col>
          </Row>
        </Col>))}
      </Form.Row>
      {/** PARTE 2: PARTIDA DE NACIMIENTO */}
      <Form.Row className="mt-5">
        <Col xs="12">
          <BadgeWrapper variant="info" style={styles.badgeNumber}>2</BadgeWrapper>
          <BadgeWrapper variant="primary">Partida de nacimiento u otros documentos:</BadgeWrapper>
        </Col>
        <Col xs="12" className="d-md-flex justify-content-md-center mt-3">
          <Form.Check type={"radio"} custom id={`radio-birth-1`}>
            <Form.Check.Input type={"radio"} name="radio-birth" value={`radioBirth1`}
              checked={radioBirth === `radioBirth1`}
              onChange={e => setRadioBirth(e.currentTarget.value)}
              onClick={() => {
                setFieldValue("certified_birth_certificate", '')
                setFieldValue("invoice", '')
                setFieldValue("book", '')
                setFieldValue("date_birth_certificate", '')
                setFieldValue("tome", '')
                setFieldValue("original_filial_data", '')
                setFieldValue("gazette", '')
                setFieldValue("proof_of_naturalization", '')

                document.getElementById(`validationFormik-certified_birth_certificate`).value = ""
                document.getElementById(`validationFormik-invoice`).value = ""
                document.getElementById(`validationFormik-book`).value = ""
                document.getElementById(`validationFormik-date_birth_certificate`).value = ""
                document.getElementById(`validationFormik-tome`).value = ""
                document.getElementById(`validationFormik-original_filial_data`).value = ""
                document.getElementById(`validationFormik-gazette`).value = ""
                document.getElementById(`validationFormik-proof_of_naturalization`).value = ""
              }}
            />
            <Form.Check.Label>
              <div style={{ transform: "translateY(2px)" }}>
                <BadgeWrapper variant="info" style={styles.badgeNumber}>a</BadgeWrapper>
                <BadgeWrapper variant="primary">Partida de nacimiento u otros documentos:</BadgeWrapper>
              </div>
            </Form.Check.Label>
          </Form.Check>
        </Col>
        <Col md="4"></Col>
        <FileInput fieldName={"birth_certificate"} labelName="" 
          disabled={radioBirth !== `radioBirth1`}
          sizes={{ xs: 12, sm: 12, md: 4, lg: 4, xl: 4 }}
          textHelp={<>Si <b>NO TIENES</b> partida de nacimiento lee cuidadosamente las opciones disponibles que la sustituirán</>}
        />
      </Form.Row>
      <Form.Row>
        <Col md="4" style={styles.textContent}>
          <Row>
            <Col xs="12">
              <Form.Check type={"radio"} custom id={`radio-birth-2`}>
                <Form.Check.Input type={"radio"} name="radio-birth" value={`radioBirth2`}
                  checked={radioBirth === `radioBirth2`}
                  onChange={e => setRadioBirth(e.currentTarget.value)}
                  onClick={() => {
                    setFieldValue("birth_certificate", '')
                    setFieldValue("original_filial_data", '')
                    setFieldValue("gazette", '')
                    setFieldValue("proof_of_naturalization", '')

                    document.getElementById(`validationFormik-birth_certificate`).value = ""
                    document.getElementById(`validationFormik-original_filial_data`).value = ""
                    document.getElementById(`validationFormik-gazette`).value = ""
                    document.getElementById(`validationFormik-proof_of_naturalization`).value = ""
                    }}
                />
                <Form.Check.Label>
                  <div className="d-flex" style={{ transform: "translateY(2px)" }}>
                    <div className="pr-1">
                      <BadgeWrapper variant="info" style={styles.badgeNumber}>b</BadgeWrapper>
                    </div>
                    <div>
                      <BadgeWrapper variant="primary" style={{ whiteSpace: "normal", textAlign: "left" }}>
                        Copia del libro o partida de nacimiento certificada
                      </BadgeWrapper>
                    </div>
                  </div>
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md="12">
              <FileInput fieldName={"certified_birth_certificate"} labelName="" 
                disabled={radioBirth !== `radioBirth2`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                textHelp={<>Emitida por la prefectura que indica <b>folio, libro, fecha</b> y <b>tomo</b>; certificada por un notario público.</>}
              />
            </Col>
            <Col md="12">
              <TextInput fieldName={"invoice"} labelName="Folio" 
                disabled={radioBirth !== `radioBirth2`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              />
              <TextInput fieldName={"book"} labelName="Libro" 
                disabled={radioBirth !== `radioBirth2`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              />
              <DateInput fieldName="date_birth_certificate" labelName="Fecha" icon={userIcon}
                  disabled={radioBirth !== `radioBirth2`}
                  sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              />
              <TextInput fieldName={"tome"} labelName="Tomo" 
                disabled={radioBirth !== `radioBirth2`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
              />
            </Col>
          </Row>
        </Col>
        {birthCertificateColOptions.map((section, index) => (<Col md="4" key={index} style={styles.textContent}>
          <Row>
            <Col xs="12">
              <Form.Check type={"radio"} custom id={`radio-birth-${index+3}`}>
                <Form.Check.Input type={"radio"} name="radio-birth" value={`radioBirth${index+3}`}
                  checked={radioBirth === `radioBirth${index+3}`}
                  onChange={e => setRadioBirth(e.currentTarget.value)}
                  onClick={() => {
                    section.setFieldValues.forEach(item => {
                        setFieldValue(item.fieldName, item.value)
                      })
                      section.setFieldValues.forEach(item => {
                        document.getElementById(`validationFormik-${item.fieldName}`).value = ""
                      })
                    }}
                />
                <Form.Check.Label>
                  <div className="d-flex" style={{ transform: "translateY(2px)" }}>
                    <div className="pr-1">
                      <BadgeWrapper variant="info" style={styles.badgeNumber}>{section.letter}</BadgeWrapper>
                    </div>
                    <div>
                      { section.badgeText && (<BadgeWrapper variant="primary" style={{ whiteSpace: "normal", textAlign: "left" }}>
                        {section.badgeText}
                      </BadgeWrapper>)}
                    </div>
                  </div>
                </Form.Check.Label>
              </Form.Check>
            </Col>
            <Col md="12">
              <FileInput fieldName={section.fileName} labelName="" 
                disabled={radioBirth !== `radioBirth${index+3}`}
                sizes={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
                textHelp={section.fileTextHelp}
              />
            </Col>
          </Row>
        </Col>))}
      </Form.Row>
    </Col>
  )
}

export default BaccalaureateDegree
