export default [

  {nombre:"Dra. Judith Aular", puesto:"Rectora"},
  {nombre: "Dr. Clotilde Navarro", puesto: "Vicerrector Académico"},
  {nombre: "Dra. Marlene Primera", puesto: "Vicerrectora Administrativa"},
  {nombre:"Dra. Ixora Gómez", puesto:"Secretaria"},
  {nombre:"Dr. Calixto Hernández", puesto:"Decano del Núcleo LUZ-COL"},
  {nombre:"Dra. Sunny Perozo", puesto:"Coordinadora de Postgrado e Investigación"},
  // {
  //   nombre:"Dra. Sunny Perozo",
  //   puesto:"Coordinadora del Doctorado",
  //   correo:""
  // },

  // {nombre: "Judith Aular", puesto: "Rectora"},
  // {nombre: "Clotilde Navarro", puesto: "Vicerrector académico"},
  // {nombre: "Marlene Primera", puesto: "Vicerrectora Administrativa"},
  // {nombre: "Ixora Gómez", puesto: "Secretaria"},
  // {nombre: "Ana Colina", puesto: "Decana interina de la Facultad de Agronomía"},
  // {nombre: "Susana Gómez", puesto: "Decana de la Facultad de Arquitectura y Diseño"},
  // {nombre: "Iván Cañizales", puesto: "Decano de la Facultad de Ciencias Económicas y Sociales"},
  // {nombre: "Diana Romero", puesto: "Decana de la Facultad de Ciencias Jurídicas y Políticas"},
  // {nombre: "José Rodriguez", puesto: "Decano de la Facultad de Ciencias Veterinarias"},
  // {nombre: "Juliana Marín", puesto: "Decana Interina de la Facultad Experimental de Arte"},
  // {nombre: "José Ortega", puesto: "Decano encargado de la Facultad Experimental de Ciencias"},
  // {nombre: "Doris Salas", puesto: "Decana de la Facultad de Humanidades y Educación"},
  // {nombre: "Mario Herrera", puesto: "Decano de la Facultad de Ingeniería"},
  // {nombre: "Sergio Osorio", puesto: "Decano de la Facultad de Medicina"},
  // {nombre: "Roberto García", puesto: "Decano encargado de la Facultad de Odontología"},
  // {nombre: "Calixto Hernández", puesto: "Decano interino del Núcleo Costa Oriental del Lago"},
  // {nombre: "Sunny Perozo", puesto:"Coordinadora de Postgrado e Investigación"},
  // {
  //   nombre:"Sunny Perozo",
  //   puesto:"Coordinadora del Doctorado",
  //   correo:""
  // },

  // {nombre:"Dr. Jorge Palencia", puesto:"Rector"},
  // {nombre:"Dra. Judith Aular", puesto:"Vicerrectora Académica"},
  // {nombre:"Dra. María Artigas ", puesto:"Vicerrectora Administrativa (E)"},
  // {nombre:"Dra. Marlene Primera", puesto:"Secretaria"},
  // {nombre:"Dr. Carlos García Mora", puesto:"Decano del Núcleo LUZ-COL"},
  // {nombre:"Dra. Sunny Perozo", puesto:"Coordinadora de Postgrado e Investigación"},
  // {
  //   nombre:"Dra. Sunny Perozo",
  //   puesto:"Coordinadora del Doctorado",
  //   correo:""
  // },
]