import React from 'react'
import { withRouter } from 'react-router-dom'
import Carousel from 'components/carousel'
import BoxAcademicDegreeContaner from 'pages/postgraduate/components/box-academic-degree/BoxAcademicDegreeContaner'
import TitleDegreeContainer from 'pages/postgraduate/components/box-title/TitleDegreeContainer'

function Home({ registerState }) {
  return (
  <>
    <Carousel registerState={registerState} />
    <TitleDegreeContainer/>
    <BoxAcademicDegreeContaner/>
  </>)
}

export default withRouter(Home);