import React, { useState } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import { useUserState } from "context/user-context";
import HomePage from 'pages/home'
import DoctoradoCienciasEducacionPage from 'pages/postgraduate/pages/doctorado-ciencias-educacion/DoctoradoCienciasEducacionPage'
import MaestriaGerenciaOperacionesPage from 'pages/postgraduate/pages/maestria-gerencia-operaciones/MaestriaGerenciaOperacionesPage';
import EspecialidadTributacionesPage from 'pages/postgraduate/pages/especialidad-tributaciones/EspecialidadTributacionesPage';
import DoctoradoGerenciaEmpresarialPage from 'pages/postgraduate/pages/doctorado-gerencia-empresarial/DoctoradoGerenciaEmpresarialPage';
import MaestriaGerenciaInnovacionPage from 'pages/postgraduate/pages/maestria-gerencia-innovacion/MaestriaGerenciaInnovacionPage';
import MaestriaEnGerenciaDeEmpresasMencionGerenciaFinancieraPage from 'pages/postgraduate/pages/maestria-en-gerencia-de-empresas-mencion-gerencia-financiera/MaestriaEnGerenciaDeEmpresasMencionGerenciaFinancieraPage';
import MaestriaEnDesarrolloDeOrganizacionesInteligentesPage from 'pages/postgraduate/pages/maestria-en-desarrollo-de-organizaciones-inteligentes/MaestriaEnDesarrolloDeOrganizacionesInteligentesPage';
import EspecialidadEnGerenciaMunicipalPage from 'pages/postgraduate/pages/especialidad-en-gerencia-municipal/EspecialidadEnGerenciaMunicipalPage';
import EspecialidadEnContaduriaMenciónAuditoriaPage from 'pages/postgraduate/pages/especialidad-en-contaduria-mencion-auditoria/EspecialidadEnContaduriaMenciónAuditoriaPage';

import Inscription from 'pages/undergraduate/pages/inscription'
import CreateInscription from 'pages/undergraduate/pages/create-inscription'
import CreatePostgraduateInscription from './pages/postgraduate/pages/create-inscription/CreateInscription'
import ShowInscriptionPayroll from 'pages/undergraduate/pages/show-inscription-payroll'
import StudentList from 'pages/postgraduate/pages/inscription-admin/StudentList'
import LoginAdmin from 'pages/postgraduate/pages/login-admin/LoginAdmin'

import 'styles/layout.scss'
import Navbar from 'components/navbar/Navbar';
import Footer from 'components/footer/Footer';

function App() {
  // const registerState = "standard";
  // const [registerState, setRegisterState] = useState("standard")
  const [registerState, setRegisterState] = useState("censo")
  // const [registerState, setRegisterState] = useState("inscripcion")
  // const registerState = "inscripcion";
  var { isAuthenticated } = useUserState();
  const isAuth = isAuthenticated()
  return (
    <BrowserRouter>
      <Navbar/>
      <Switch>
          <Route exact path="/" render={(props) => <HomePage {...props} registerState={registerState} />} /> 
          {/* <Route exact path="/maestria-gerencia-operaciones" component={MaestriaGerenciaOperacionesPage}/> */}
          <Route exact path="/maestria-gerencia-operaciones" component={MaestriaGerenciaOperacionesPage}/>
          <Route exact path="/maestria-gerencia-innovacion" component={MaestriaGerenciaInnovacionPage}/>
          <Route exact path="/doctorado-ciencias-educacion" component={DoctoradoCienciasEducacionPage}/>
          <Route exact path="/doctorado-gerencia-empresarial" component={DoctoradoGerenciaEmpresarialPage}/>
          <Route exact path="/especialidad-tributaciones" component={EspecialidadTributacionesPage}/>
          <Route exact path="/maestria-en-gerencia-de-empresas-mencion-gerencia-financiera" component={MaestriaEnGerenciaDeEmpresasMencionGerenciaFinancieraPage}/>
          <Route exact path="/maestria-en-desarrollo-de-organizaciones-inteligentes" component={MaestriaEnDesarrolloDeOrganizacionesInteligentesPage}/>
          <Route exact path="/especialidad-en-gerencia-municipal" component={EspecialidadEnGerenciaMunicipalPage}/>
          <Route exact path="/especialidad-en-contaduria-mencion-auditoria" component={EspecialidadEnContaduriaMenciónAuditoriaPage}/>
          {/* <Route exact path="/inscripcion" component={Inscription}/> */}
          {/* <Route exact path="/inscripcion/solicitud-de-datos" component={CreateInscription}/> */}
          <Route exact path="/postgrado/censo" component={CreatePostgraduateInscription}/>
          {/* <Route exact path="/inscripcion/planilla/:payroll_number" component={ShowInscriptionPayroll}/> */}
          <PublicRoute exact path="/postgrado/censo/login" component={LoginAdmin}/>
          <PrivateRoute path="/postgrado/censo/admin" component={StudentList} />
      </Switch>
      <Footer 
        title="Universidad del Zulia. Núcleo Costa Oriental del Lago. División de estudios para graduados. Urb. Las 40 entre calles 4 y 5. Cabimas Edo Zulia 4013."
      />
    </BrowserRouter>
  );

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route {...rest}
        render={props => isAuth ? ( React.createElement(component, props) ) : 
          ( <Redirect to={"/inscripcion/login"} /> )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route {...rest}
        render={props => isAuth ? ( <Redirect to={{ pathname: "/inscripcion/admin" }} /> ) : 
          ( React.createElement(component, props) )
        }
      />
    );
  }
}


export default App;
