import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';
import {
  Button,
  Col,
  Container, Form, Modal, Row,
} from 'react-bootstrap'
import { Formik } from 'formik'
import Axios from 'axios'

import Header from '../../components/header'
import { schema, initialValues } from './components/validation-schema'
import StudentForm from './components/student-form'
import styles from './styles'
import { API_URL } from '../../../../constants'

const CreateInscription = () => {
  const history = useHistory();
  const [loadingForm, setLoadingForm] = useState(false)

  const [showResidenceModal, setShowResidenceModal] = useState(true);

  const [residencia, setResidencia] = useState(null)

  const handleCloseResidenceModal = () => setShowResidenceModal(false);
  const handleShowResidenceModal = () => setShowResidenceModal(true);

  const residenceOptions = [
    { id: 1, name: 'Venezolano en Venezuela (Inscripción presencial)' },
    { id: 2, name: 'Venezolano en el exterior' },
    { id: 3, name: 'Extranjero' }
  ]

  return (<>
    <Container fluid style={styles.global}>
      <Container style={styles.container}>
        <Container>
          <Header />
          <Formik
            validationSchema={schema}
            onSubmit={(values, actions) => {

              Axios.post(`${API_URL}/postgraduateCensu`, {
                ...values,
                residence_id: residencia
              })
              .then(response => {
                console.log(response);
                alert("Se ha censado satisfactoriamente")
                history.push(`/`)
              }).catch(error => {
                console.log(error)
                const err = Object.assign({}, error)
                console.log(err)
                setLoadingForm(false)
                try {
                  if (err.response.status === 422) {
                    for (const [key, value] of Object.entries(err.response.data.errors)) {
                      alert(value[0])
                      break;
                    }
                  }

                } catch (exception) {}
                actions.setSubmitting(false);
              })

            }}
            initialValues={initialValues}
          >
            <StudentForm
              loadingForm={loadingForm}
              setLoadingForm={setLoadingForm}
              residenceId={residencia}
              residenceOptions={residenceOptions}
            />
          </Formik>
          <Modal size='xl' show={showResidenceModal} onHide={() => {
            if (!residencia) {
              setResidencia(1)
            }
            handleCloseResidenceModal()
          }}>
            <Modal.Header closeButton>
              <Modal.Title>SELECCIONE LA OPCIÓN EN LA QUE RESIDE</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className='d-flex justify-content-center'>
                  <div>
                    {residenceOptions.map(residenceOption => (
                      <Form.Check
                        type={'radio'}
                        label={`${residenceOption.name}`}
                        id={`residencia-radio-${residenceOption.id}`}
                        name='residencia'
                        onChange={() => setResidencia(residenceOption.id)}
                      />
                    ))}
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="primary" onClick={handleCloseResidenceModal}
                disabled={!residencia}
              >
                Seleccionar
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Container>
    </Container>
  </>)
}

export default CreateInscription
