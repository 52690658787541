import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'


const RequisitoDeIngreso = (props) => {
  return (
    <>
      <Container  fluid className="requisito-de-ingreso ">
        <Row className="requisito-de-ingreso-row">
          <Col xs={12} sm={12} md={12} lg={10} xl={8} className="requisito-de-ingreso-col" >
            <h1 className="requisito-de-ingreso-titulo">{props.title}</h1>
            <ul className="requisito-de-ingreso-parrafo">
              {props.description.map((e,key)=> (<li key={key}>{e.a}</li>))}
            </ul>   
          </Col>
        </Row>
      </Container>
    </> 
  )
}

export default RequisitoDeIngreso
