import React from 'react'
import CarouselComponent from 'pages/postgraduate/pages/components/carousel/Carousel'
import TitleDegree from 'pages/postgraduate/components/box-title/TitleDegree'
import { Col, Container, Row } from 'react-bootstrap'
import DuracionDelPrograma from '../components/duracion-del-programa/DuracionDelPrograma'
import RequisitoDeIngreso from '../components/requisito-de-ingreso/RequisitoDeIngreso'
import CompetenciasDelEgresado from '../components/competencias-del-egresado/CompetenciasDelEgresado'
// import PlanDeEstudio from '../components/plan-de-estudio/PlanDeEstudio'
import image from 'pages/postgraduate/pages/components/carousel/doctorado_gerencia.jpg'
import EstructuraCurricular from '../components/estructura-curricular/EstructuraCurricular'
import AutoridadesUniversitarias from '../components/autoridades-universitarias/AutoridadesUniversitarias'
import Autoridades from '../../data/authorities'

const EspecialidadEnGerenciaMunicipalPage = ({ registerState }) => {
  const requisitos = [
    {a:"Fondo negro o blanco del título académico  certificado por la universidad de la cual egresó."},
    {a:"Notas certificadas de pregrado certificado por la universidad de la cual egresó."},
    {a:"Currículo vitae simple."},
    {a:"Fotocopia de la cédula de identidad."},
    {a:"Partida de nacimiento en original o copia certificada."},
    {a:"Dos (2) fotos de frente tamaño carnet."},
    {a:"Fotocopia de la inscripción militar."},
  ]
  const semestres = [
    {
      semestre: "Área de Nivelación",
      contenidos : [
        {materia: 'Administración y gerencia'},
        {materia: 'Entornos virtuales de aprendizaje'},
        {materia: 'Estadística social'},
      ]
    },
    {
      semestre: "I Cuatrimestre",
      uc :"U/C ",
      contenidos : [
        {materia: 'Gerencia municipal', uc:2},
        {materia: 'Análisis de problema y toma de decisiones', uc:2},
        {materia: 'Legislación en la gestión de gobierno', uc:2},
      ]
    },
    { 
      semestre : 'II Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Planificación para el desarrollo local, regional y nacional', uc:2},
        {materia: 'Gerencia de gobierno y servicios públicos', uc:2},
        {materia: 'Legislación tributaria', uc:2},
        {materia: 'Seminario: Epistemología de la investigación', uc:2},
      ]
    },
    { 
      semestre : 'III Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Mecanismos de protección de los derechos fundamentales en el ámbito internacional', uc:2},
        {materia: 'Práctica profesional I', uc:2},
        {materia: 'Marketing político', uc:2},
        {materia: 'Electiva', uc:2},
      ]
    },
    { 
      semestre : 'IV Cuatrimestre',
      uc :"U/C ",
      contenidos : [
        {materia: 'Práctica Profesional II', uc:	2},
        {materia: 'Trabajo especial de grado', uc:	6},
      ]
    },
    { 
      semestre : 'Electivas',
      uc :"U/C ",
      contenidos : [
        {materia: 'Convivencia y participación ciudadana en la gestión pública', uc:2},
        {materia: 'Finanzas y presupuesto público', uc:2},
        {materia: 'Modernización de la estrategia pública', uc:2},
      ]
    },
  ]
  const competencias = [
    {a:"Apoyar el desarrollo estratégico de gobierno en los contextos regional, nacional e internacional, relacionado con los aspectos político, social y económico"},
    {a:"Aplicar habilidades gerenciales y de liderazgo para formar equipos de alto desempeño, promoviendo los cambios requeridos por las instituciones gubernamentales."},
    {a:"Generar los conocimientos específicos para la toma de decisiones en un ambiente de incertidumbre y cambios permanentes."},
    {a:"Aplicar estrategias gerenciales, basadas en criterios de racionalidad y eficiencia, que coadyuven a la optimización de los procesos de planificación, organización, dirección y control en el ámbito de las organizaciones del poder público municipal."},
    {a:"Aplicar las competencias en materia de legislación tributaria a nivel municipal, estadal, nacional e internacional fin de que gestione eficientemente las distintas necesidades derivadas de las políticas públicas en cada contexto."},
    {a:"Capacidad para solucionar los problemas de índole institucional y de los servicios públicos"},
    {a:"Poseer las destrezas necesarias para asumir la gerencia de las organizaciones e instituciones del poder público municipal de una nación"},
  ]

  return (
    <>
      <CarouselComponent
        title = "Especialista en Gerencia Municipal"
        description= ""
        image ={image}
        registerState={registerState}
      />
      <Container fluid >
        <Row>
          <Col>
            <hr></hr>
            <TitleDegree
              titulo="Objetivo General"
              description="La Especialidad en Gerencia Municipal busca desarrollar competencias para el ejercicio del liderazgo, dirección y gestión de cambios requeridos en los gobiernos promoviendo el desarrollo estratégico de políticas en sus sistemas administrativos."
              ColorBorder="description-degree-green"
            />
            <hr></hr>           
          </Col>
        </Row>
        <Row>
          <DuracionDelPrograma
            title ="Duración del programa"
            description = "El programa tiene una duración de V períodos académicos (cuatrimestres) de 12 semanas cada uno."
          />
        </Row>
        <Row>
          <RequisitoDeIngreso
            title="Requisitos de Ingreso"
            description={requisitos}
          />
        </Row>
        <Row>
          <CompetenciasDelEgresado
            title="Competencias del Egresado"
            description={competencias}
          />
        </Row>
        <Row>
          <EstructuraCurricular
            semestres={semestres}
            totaluc =""
            color ="estructura-curricular-card-padre-green"
          />   
        </Row>
        <Row>
          <AutoridadesUniversitarias
            Autoridades={[
              ...Autoridades,
            ]}
          />
        </Row>
      </Container>
    </>
  )
}

export default EspecialidadEnGerenciaMunicipalPage
