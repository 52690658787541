import React, { useState } from 'react'
import Axios from 'axios'
import { Modal, Spinner, Button, Alert } from 'react-bootstrap'

import { API_URL } from '../../../../constants'

const VerifyUserConfirm = ({ showConfirmModal, handleCloseConfirmModal, selectedStudent, setStudents }) => {
  const [isLoading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertVariant, setAlertVariant] = useState("danger")
  const [alertMessage, setAlertMessage] = useState("")

  const confirmUser = selectedStudent => {
    setLoading(true)
    let token = localStorage.getItem('token');
    Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
    Axios.defaults.headers.common['Content-Type'] = "application/json";
    return Axios.post(`${API_URL}/student/${selectedStudent.id}/verify`, {})
    .then(response => {         
      setLoading(false)
      
      Axios.get(`${API_URL}/students`)
      .then(response => setStudents(response.data))
      .catch(error => {
        console.log(error)
        const err = Object.assign({}, error)
        console.log(err)
        setStudents([])
      })
      
      handleCloseConfirmModal()
    }).catch( error => {
        setAlertMessage("Se ha producido un error, contacte a soporte")
        setLoading(false)
        setShowAlert(true)
        setAlertVariant("danger")
        
        console.log(Object.assign({}, error))
    })
  }

  return (
    <Modal
      show={showConfirmModal} 
      onHide={handleCloseConfirmModal}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {isLoading ?
      (<>
      <div 
      style={{
          position: 'absolute', zIndex: 3, 
          marginLeft: '-50px', left: '50%',
          marginTop: '-50px', top: '50%'
      }}
      >
          <Spinner style={{height: '100px', width: '100px'}} animation="border" variant="primary" />
      </div>
      <div 
      style={{
        position: 'absolute', zIndex: 2, 
        marginLeft: '0', left: '0',
        marginTop: '0', top: '0',
        height: '100%', width: '100%',
        backgroundColor: '#00000044'
      }}
      >
      </div>
      </>)
      : ('')}
      <Modal.Header className='bg-primary py-2' closeButton>
        <Modal.Title className="h6 text-center text-white">¿Desea verificar al estudiante {selectedStudent.firstname} {selectedStudent.lastname}?</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-justify">
        {showAlert ? 
          (<Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
            <div style={{fontSize: "1rem", textAlign: "center"}}>
            {alertMessage}
            </div>
          </Alert>) :
          (<></>)
        }
      </Modal.Body>
      <Modal.Body className="text-justify">
        Al verificar a un estidante está validando que sus documentos fueron entregado de manera física ¿Desea verificar?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-around py-2">
        <Button variant="danger" size="sm" onClick={handleCloseConfirmModal}>
          Cancelar
        </Button>
        <Button variant="success" size="sm" onClick={() => confirmUser(selectedStudent)}>
          Confirmar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default VerifyUserConfirm
